import { memo, useRef } from "react";
import { isMobile } from "react-device-detect";
import OfferingBox from "../../../components/offering-box/OfferingBox";
import { useTranslation } from "react-i18next";

const Offerings = () => {
    const sectionRef = useRef();
    const { t } = useTranslation();
   
    const contentData = [
        {
            title: t("HOME.OFFERINGS.DEFAULT"),
        },
        {
            title: t("HOME.OFFERINGS.TITLE1"),
            text: t("HOME.OFFERINGS.TEXT1"),
            icon: "ic-progress.png",
        },
        {
            title: t("HOME.OFFERINGS.TITLE2"),
            text: t("HOME.OFFERINGS.TEXT2"),
            icon: "ic-progress.png",
        },
        {
            title: t("HOME.OFFERINGS.TITLE3"),
            text: t("HOME.OFFERINGS.TEXT3"),
            icon: "ic-progress.png",
        },
        {
            title: t("HOME.OFFERINGS.TITLE4"),
            text: t("HOME.OFFERINGS.TEXT4"),
            icon: "ic-progress.png",
        },
        {
            title: t("HOME.OFFERINGS.TITLE5"),
            text: t("HOME.OFFERINGS.TEXT5"),
            icon: "ic-progress.png",
        },
    ];

    
    //Mobile device transition x value change
    

    return (
        <section
            ref={sectionRef}
            className={`${isMobile ? "h-[100vh]" : "h-[100vh]"
                } w-full`}
        >
            <div className="h-screen 2xl:min-h-[866px] lg:min-h-[735px] md:min-h-[675px] min-h-[562px] sticky top-0">
                <div className="h-full flex flex-col justify-center overflow-hidden pt-15">
                    <div
                        className={`animated relative fadeInUp w-full m-auto lg:mb-0 lg:max-w-none max-w-[575px]`}
                        style={{ animationDelay: ".3s" }}
                    >
                        <OfferingBox contentData={contentData} />
                    </div>
                    <div className="animated fadeInUp bar-blur lg:!mb-16" style={{ animationDelay: ".3s" }}></div>
                </div>
            </div>
        </section>
    );
};

export default memo(Offerings);