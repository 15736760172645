import { Fragment, useRef, useState } from "react";
import { Formik, Form, Field } from "formik";
import axios from "axios";
import { H2_MD, P_SM } from "../../Typography";
import countryList from "../../../data/country.json";
import "./InvestWithUs.scss";
import { investwithus } from "../../../constants";
import SuccessModalContainer from "../successModal/SuccessModalContainer";
import InvestValidate from "./InvestValidate";
import InvestInput from "./InvestInput";
import Loader from "../../../assets/images/loader.svg";
import { useTranslation } from "react-i18next";

const InvestWithUsForm = ({ closeModalState }) => {
  const modalRef = useRef();
  const formRef = useRef();
  const successModalRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState("");
  const { t } = useTranslation();

  const onSubmit = (values, { resetForm, setSubmitting }) => {
    setSubmitting(true);
    const response = axios.post(investwithus.api, values, {
      headers: {
        language: localStorage.getItem("language") || "tr",
      },
    });

    response
      .then((responseData) => {
        if (responseData.data.success === true) {
          formZoomOut(formRef);
          setIsOpen(true);
          resetForm();
        } else {
          setError(investwithus.error);
        }
      })
      .catch((err) => {
        console.log(err);
        setError(investwithus.error);
      })
      .finally(() => {
        setSubmitting(false);
      });

  };
  const onChange = () => setError("");

  const closeModal = () => {
    modalRef.current.classList.add("fadeOut");
    modalRef.current.style.animationDelay = "0.3s";
  };

  const formZoomOut = (ref) => {
    ref.current.classList.add("zoomOut");
    ref.current.style.animationDelay = "0s";
  };

  const disposeModal = (formRef) => {
    closeModal();
    formZoomOut(formRef);
    closeModalState();
  };

  const displayHandle = (e) => {
    if (e.target.closest(".investform-container") === null && !isOpen)
      disposeModal(formRef);
  };

  const scrollHandle = () => {
    if (modalRef.current) {
      isOpen ? disposeModal(successModalRef) : disposeModal(formRef);
    }
  };

  window.addEventListener("scroll", scrollHandle);

  return (
    <>
      <div
        onClick={displayHandle}
        ref={modalRef}
        className="investform fixed top-0 left-0 flex justify-center items-center h-screen w-full min-w-[375px] z-50 backdrop-blur-sm animated fadeIn"
      >
        <div
          ref={formRef}
          className="investform-container md:px-10 md:py-8 p-4 overflow-y-auto animated zoomIn"
          style={{ animationDelay: "0.5s" }}
        >
          <button
            className="absolute md:right-10 right-3 md:top-8 top-3 investform-close"
            onClick={() => disposeModal(formRef)}
          >
            <span></span>
            <span></span>
          </button>
          <H2_MD className="lg:!text-[68px] lg:!leading-[60px] text-center">
            {t("FORM.INVEST.TITLE")}
          </H2_MD>
          <P_SM className="text-center text-silver-sand lg:leading-6 leading-4">
            {t("FORM.INVEST.DESCRIPTION")}
          </P_SM>
          <Formik
            initialValues={{
              firstName: "",
              lastName: "",
              email: "",
              investorType: "",
              highNetWorth: "",
              investmentAmount: "",
              citizenship: "",
              phone: "",
              message: "",
            }}
            validationSchema={InvestValidate}
            onSubmit={onSubmit}
          >
            {({ errors, touched, isSubmitting, values }) => (
              <Form className="md:mt-6 mt-3 grid grid-cols-2 md:gap-x-4 md:gap-y-8 gap-y-6 gap-3">
                <InvestInput
                  name="firstName"
                  type="text"
                  placeholder={t("FORM.INVEST.NAME")}
                  onKeyUp={onChange}
                  className={`col-span-1  ${(errors.firstName &&
                    touched.firstName &&
                    values.firstName !== "") ||
                    (touched.firstName && error !== "")
                    ? "is-invalid"
                    : ""
                    }`}
                >
                  {(errors.firstName &&
                    touched.firstName &&
                    values.firstName !== "") ||
                    (touched.firstName && error !== "") ? (
                    <div className="is-invalid-message">{errors.firstName}</div>
                  ) : null}
                </InvestInput>
                <InvestInput
                  name="lastName"
                  type="text"
                  placeholder={t("FORM.INVEST.LNAME")}
                  onKeyUp={onChange}
                  className={`col-span-1  ${(errors.lastName &&
                    touched.lastName &&
                    values.lastName !== "") ||
                    (touched.lastName && error !== "")
                    ? "is-invalid"
                    : ""
                    }`}
                >
                  {(errors.lastName &&
                    touched.lastName &&
                    values.lastName !== "") ||
                    (touched.lastName && error !== "") ? (
                    <div className="is-invalid-message">{errors.lastName}</div>
                  ) : null}
                </InvestInput>
                <InvestInput
                  onKeyUp={onChange}
                  className={`md:col-span-1 col-span-2  ${(errors.email && touched.email && values.email !== "") ||
                    (touched.email && error !== "")
                    ? "is-invalid"
                    : ""
                    }`}
                  name="email"
                  type="text"
                  placeholder={t("FORM.INVEST.EMAIL")}
                >
                  {(errors.email && touched.email && values.email !== "") ||
                    (touched.email && error !== "") ? (
                    <div className="is-invalid-message">{errors.email}</div>
                  ) : null}
                </InvestInput>
                <InvestInput
                  className={`md:col-span-1 col-span-2 ${(errors.investorType &&
                    touched.investorType &&
                    values.investorType !== "") ||
                    (touched.investorType && error !== "")
                    ? "is-invalid"
                    : ""
                    }`}
                  name="investorType"
                  type="text"
                  placeholder={t("FORM.INVEST.TYPE")}
                  onKeyUp={onChange}
                >
                  {(errors.investorType &&
                    touched.investorType &&
                    values.investorType !== "") ||
                    (touched.investorType && error !== "") ? (
                    <div className="is-invalid-message">{errors.investorType}</div>
                  ) : null}
                </InvestInput>
                <InvestInput
                  className={`col-span-1  ${(errors.highNetWorth &&

                    touched.highNetWorth &&
                    values.highNetWorth !== "") ||
                    (touched.highNetWorth && error !== "")
                    ? "is-invalid"
                    : ""
                    }`}
                  name="highNetWorth"
                  type="number"
                  placeholder={t("FORM.INVEST.WORTH")}
                  onKeyUp={onChange}
                >
                  {(errors.highNetWorth &&
                    touched.highNetWorth &&
                    values.highNetWorth !== "") ||
                    (touched.highNetWorth && error !== "") ? (
                    <div className="is-invalid-message">{errors.highNetWorth}</div>
                  ) : null}
                </InvestInput>
                <InvestInput
                  className={`col-span-1  ${(errors.investmentAmount &&
                    touched.investmentAmount &&
                    values.investmentAmount !== "") ||
                    (touched.investmentAmount && error !== "")
                    ? "is-invalid"
                    : ""
                    }`}
                  name="investmentAmount"
                  type="number"
                  placeholder={t("FORM.INVEST.AMOUNT")}
                  onKeyUp={onChange}
                >
                  {(errors.investmentAmount &&
                    touched.investmentAmount &&
                    values.investmentAmount !== "") ||
                    (touched.investmentAmount && error !== "") ? (
                    <div className="is-invalid-message">{errors.investmentAmount}</div>
                  ) : null}
                </InvestInput>
                <div
                  className={`md:col-span-1 col-span-2 ${(errors.citizenship &&
                    touched.citizenship &&
                    values.citizenship !== "") ||
                    (touched.citizenship && error !== "")
                    ? "is-invalid"
                    : ""
                    }`}
                >
                  <Field
                    className={`investform-input select-input cursor-pointer outline-0 w-full appearance-none md:h-[55px] h-11 md:py-4  md:px-7 px-4 md:rounded-2xl rounded-xl md:text-base text-base-m !leading-none`}
                    name="citizenship"
                    as="select"
                    required
                  >
                    <option value="" disabled>
                      {t("FORM.INVEST.CITIZENSHIP")}
                    </option>
                    {countryList.map((country, index) => (
                      <option key={index} value={country.code}>
                        {country.name}
                      </option>
                    ))}
                  </Field>
                  {(errors.citizenship &&
                    touched.citizenship &&
                    values.citizenship !== "") ||
                    (touched.citizenship && error !== "") ? (
                    <div className="is-invalid-message">{errors.citizenship}</div>
                  ) : null}
                </div>
                <InvestInput
                  className={`md:col-span-1 col-span-2  ${(errors.phone &&
                    touched.phone &&
                    values.phone !== "") ||
                    (touched.phone && error !== "")
                    ? "is-invalid"
                    : ""
                    }`}
                  name="phone"
                  type="text"
                  placeholder={t("FORM.INVEST.PHONE")}
                  onKeyUp={onChange}
                >
                  {(errors.phone &&
                    touched.phone &&
                    values.phone !== "") ||
                    (touched.phone && error !== "") ? (
                    <div className="is-invalid-message">{errors.phone}</div>
                  ) : null}
                </InvestInput>
                <InvestInput
                  className={`col-span-2 !leading-none ${errors.message && touched.message ? "is-invalid" : ""
                    }`}
                  name="message"
                  as="textarea"
                  placeholder={t("FORM.INVEST.MESSAGE")}
                  onKeyUp={onChange}
                >
                  {errors.message && touched.message ? (
                    <div className="is-invalid-message">
                      {values.message.length > 0 &&
                        (values.message.length < 50 ||
                          values.message.length > 300)
                        ? `${errors.message} ${values.message.length}/ ${values.message.length < 50 ? "50" : "300"
                        } Characters!`
                        : errors.message}
                    </div>
                  ) : null}
                </InvestInput>
                <div className="col-span-2">
                  <button
                    className="investform-send-btn text-center w-full font-heading md:h-[75px] h-12 md:rounded-2xl rounded-xl md:text-xl text-2xl-m text-black relative top-0 duration-300 hover:top-[-3px] outline-0 flex items-center justify-center"
                    type="submit"
                    disabled={isSubmitting || Object.keys(errors).length > 0 || !values.firstName || !values.lastName || !values.email || !values.phone || !values.investorType || !values.highNetWorth || !values.investmentAmount || !values.citizenship}
                  >
                    {isSubmitting ? <img src={Loader} alt="" /> : t("FORM.INVEST.BUTTON")}
                  </button>
                  {error !== "" ? (
                    <div className="is-invalid-message !relative !bottom-0 text-center mt-3">
                      {error}
                    </div>
                  ) : null}
                </div>
              </Form>
            )}
          </Formik>
        </div>
        {isOpen ? (
          <SuccessModalContainer
            ref={successModalRef}
            disposeModal={disposeModal}
            title={t("FORM.INVEST.SUCCESS.TITLE")}
            description={
              <Fragment>
                {t("FORM.INVEST.SUCCESS.DESCRIPTION")}
                <br className="md:block hidden" /> {t("FORM.INVEST.SUCCESS.DESCRIPTION2")}
              </Fragment>
            }
          />
        ) : null}
      </div>
    </>
  );
};

export default InvestWithUsForm;
