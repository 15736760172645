import * as Yup from "yup";
import { investwithus } from "../../../constants";

const InvestValidate = Yup.object().shape({
  firstName: Yup.string()
    .min(2, investwithus.min)
    .max(50, investwithus.max)
    .required(investwithus.required.firstName),
  lastName: Yup.string()
    .min(2, investwithus.min)
    .max(50, investwithus.max)
    .required(investwithus.required.lastName),
  email: Yup.string()
    .email(investwithus.validation.email)
    .required(investwithus.required.email),
  investorType: Yup.string()
    .min(2, investwithus.min)
    .max(50, investwithus.max)
    .required(investwithus.required.investorType),
  investmentAmount: Yup.number()
    .max(999999999, investwithus.max)
    .required(investwithus.required.investmentAmount),
  highNetWorth: Yup.number()
    .max(999999999, investwithus.max)
    .required(investwithus.required.highNetWorth),
  phone: Yup.string()
    .matches(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/, investwithus.validation.phone)
    .required(investwithus.required.phone),
  citizenship: Yup.string().required(investwithus.required.citizenship),
  message: Yup.string()
    .max(300, "Maximum of")
});

export default InvestValidate;
