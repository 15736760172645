import { useSelector } from "react-redux";
import { Route, Routes, useLocation } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Footer from "./layout/footer/Footer";
import Header from "./layout/header/Header";
import About from "./pages/about/About";
import Career from "./pages/Career";
// import CovenlabsClub from "./pages/CovenlabsClub";
import Media from "./pages/media/Media";
//import BlogDetail from "./pages/blogDetail/BlogDetail";
// import RecentProject from "./pages/RecentProject";

const routesConfig = [
  {
    path: "/",
    element: <About />,
  },
  // {
  //   path: "/covenlabs-club",
  //   element: <CovenlabsClub />,
  // },
  {
    path: "/career",
    element: <Career />,
  },
  {
    path: "/media",
    element: <Media />,
  },
  {
    path: "*",
    element: <About />,
  }
  // ,
  // {
  //   path: "/blog/:index",
  //   element: <BlogDetail />,
  // }

  // {
  //   path: "/recent-projects",
  //   element: <RecentProject />,
  // },
];

const RouteComponent = () => {
  const location = useLocation();
  const timeout = { enter: 1000, exit: 300 };
  const { introComplete } = useSelector((state) => state.intro); //intro video izleme durumu

  return (
    <>
      {introComplete ? <Header /> : null}
      <TransitionGroup>
        <CSSTransition
          key={location.pathname}
          classNames="fade"
          timeout={timeout}
        >
          <div className="absolute top-0 left-0 right-0 bottom-0 min-w-[375px]">
            <Routes location={location}>
              {routesConfig.map((route, i) => (
                <Route key={i} path={route.path} element={route.element} />
              ))}
            </Routes>
            {introComplete ? <Footer /> : null}
          </div>
        </CSSTransition>
      </TransitionGroup>
    </>
  );
};

export default RouteComponent;
