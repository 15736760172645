import { configureStore } from "@reduxjs/toolkit";
// import logger from "redux-logger";
import introSlice from "./features/intro/IntroSlice";

const store = configureStore({
  reducer: {
    intro: introSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export default store;
