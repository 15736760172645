import { forwardRef, memo, useState } from "react";
import { Button } from "../../../components/form/button/Button";
import { H2_LG, H3_LG, P_LG, P_MD } from "../../../components/Typography";
import { useScrollAnimate } from "../../../hooks/useScrollAnimate";
import Round from "../../../assets/images/fund-round.png";
import InvestWithUsForm from "../../../components/form/invest/InvestWithUs";
import { useTranslation } from "react-i18next";

const Web3Fund = (props, sectionRef) => {
  const [isOpen, setIsOpen] = useState(false);

  const closeModalState = () => {
    setTimeout(() => {
      setIsOpen(false);
    }, 800);
  };

  const { t } = useTranslation();

  const scRatio = useScrollAnimate({
    ref: sectionRef,
    scenes: 2,
  });
  const styles = {
    a1: {
      color: "white",
      WebkitTextFillColor:
        scRatio >= 1
          ? "transparent"
          : scRatio < 0.5
            ? "white"
            : `rgba(255,255,255, ${1 - (scRatio - 0.5) * 2})`,
      WebkitTextStrokeWidth: "1px",
      WebkitTextStrokeColor: "white",
      textFillColor: "transparent",
      opacity: scRatio >= 1 ? "1" : scRatio < 0.5 ? "0" : (scRatio - 0.5) * 2,
      transform: `translateX(${scRatio >= 1
        ? "0px"
        : scRatio < 0.5
          ? "-50%"
          : `-${50 - (scRatio - 0.5) * 100}%`
        })`,
    },
    a2: {
      opacity: scRatio >= 1 ? "1" : scRatio < 0.5 ? "0" : (scRatio - 0.5) * 2,
      transform: `translateX(${scRatio >= 1
        ? "0px"
        : scRatio < 0.5
          ? "-50%"
          : `${50 - (scRatio - 0.5) * 100}%`
        })`,
    },
    a3: {
      opacity: scRatio >= 1.15 ? "1" : scRatio < 1 ? "0" : (scRatio - 1) * 6.66,
      transform: `translateY(${scRatio >= 1.15
        ? "0px"
        : scRatio < 1
          ? "50%"
          : `${50 - (scRatio - 1) * 333}%`
        })`,
    },
    a4: {
      opacity:
        scRatio >= 1.3 ? "1" : scRatio < 1.15 ? "0" : (scRatio - 1.15) * 4,
      transform: `translateY(${scRatio >= 1.3
        ? "0px"
        : scRatio < 1.15
          ? "50%"
          : `${50 - (scRatio - 1.15) * 333}%`
        })`,
    },
    a5: {
      opacity:
        scRatio >= 1.45 ? "1" : scRatio < 1.3 ? "0" : (scRatio - 1.3) * 4,
      transform: `translateY(${scRatio >= 1.45
        ? "0px"
        : scRatio < 1.3
          ? "50%"
          : `${50 - (scRatio - 1.3) * 333}%`
        })`,
      background:
        "linear-gradient(180deg, #FFFFFF -68.33%, rgba(255, 255, 255, 0) 126.67%)",
      WebkitTextFillColor: "transparent",
      WebkitBackgroundClip: "text",
      backgroundClip: "text",
      textFillColor: "transparent",
    },
    a6: {
      opacity:
        scRatio >= 1.6 ? "1" : scRatio < 1.45 ? "0" : (scRatio - 1.45) * 4,
      transform: `translateY(${scRatio >= 1.6
        ? "0px"
        : scRatio < 1.45
          ? "50%"
          : `${50 - (scRatio - 1.45) * 333}%`
        })`,
    },
    a7: {
      opacity:
        scRatio >= 1.75 ? "1" : scRatio < 1.6 ? "0" : (scRatio - 1.6) * 4,
      transform: `translateY(${scRatio >= 1.75
        ? "0px"
        : scRatio < 1.6
          ? "50%"
          : `${50 - (scRatio - 1.6) * 333}%`
        })`,
    },
  };

  return (
    <>
      <section
        ref={sectionRef}
        className="md:h-[calc(200vh+568px)] h-[calc(200vh+40px)] md:mb-36  bg-black relative z-10"
      >
        <div className="h-screen xl:min-h-[674px] lg:min-h-[658px] min-h-[600px] sticky top-0">
          <div className="h-full w-full lg:px-120 md:px-10 px-4 flex flex-col justify-center items-center lg:pt-[110px] pt-20">
            <div className="text-center xl:max-w-[830px] max-w-[690px]">
              <H2_LG>
                <span className="flex justify-center" style={styles.a1}>
                  {t("FUND.TITLE")}
                </span>
                <span className="flex justify-center" style={styles.a2}>
                  {t("FUND.TITLE2")}
                </span>
              </H2_LG>
              <P_LG className="lg:mt-5 mt-2 text-silver-sand" style={styles.a3}>
                {t("FUND.DESCRIPTION")}
              </P_LG>
              <P_MD className="lg:mt-3 mt-2 text-silver-sand" style={styles.a4}>
                {t("FUND.DESCRIPTION2")}
              </P_MD>
              <H3_LG className="lg:mt-12 mt-7 text-lg" style={styles.a5}>
                {t("FUND.DESCRIPTION3")}
              </H3_LG>
              <Button
                className="mt-3"
                style={styles.a6}
                onClick={() => setIsOpen(true)}
              >
                {t("FUND.INVEST")}
              </Button>
              <P_MD className="mt-8 text-silver-sand" style={styles.a7}>
                <div dangerouslySetInnerHTML={{ __html: t('FUND.DESCRIPTION4') }} />
              </P_MD>
            </div>
          </div>
          <div className="md:h-[calc(100%+568px)] h-[calc(100%+40px)] absolute top-0 left-0 right-0 w-full overflow-hidden -z-10">
            <img
              src={Round}
              alt=""
              style={styles.roundLeft}
              className="rotate-1 -z-10 max-w-none absolute md:top-[calc(50%-780px)] top-[calc(50%-270px)] opacity-60 xl:left-[calc(50vw-1575px)] md:left-[calc(50vw-1300px)] left-[calc(50vw-620px)] md:w-auto w-[600px]"
            />
            <img
              src={Round}
              alt=""
              style={styles.roundRight}
              className="rotate-1 -scale-x-100 rot max-w-none absolute md:top-[calc(50%-780px)] top-[calc(50%-270px)] opacity-60 xl:right-[calc(50vw-1575px)] md:right-[calc(50vw-1300px)] right-[calc(50vw-620px)] md:w-auto w-[600px]"
            />
          </div>
        </div>
      </section>
      {isOpen ? <InvestWithUsForm closeModalState={closeModalState} /> : null}
    </>
  );
};

export default memo(forwardRef(Web3Fund));
