import { memo, useCallback, useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { H2_MD, P_2MD } from "../../../components/Typography";
import Box from "../../../components/box/Box";
import { useScrollAnimate } from "../../../hooks/useScrollAnimate";
import { usePageLoad } from "../../../hooks/usePageLoad";
import useChangeWidth from "../../../hooks/useChangeWidth";
import { useTranslation } from "react-i18next";

const Development = () => {
  const [xValue, setXvalue] = useState();
  const sectionRef = useRef();
  const listContainerRef = useRef();
  const isLoad = usePageLoad();
  const [transition, setTransition] = useState("");
  const isSize = useChangeWidth(768);
  const scRatio = useScrollAnimate({
    ref: sectionRef,
    scenes: isMobile ? 1 : 6,
  });

  //yatay carousel içeriğinin resize olma durumunda tekrardan width değerinin alınması x-callback
  const xCb = useCallback(() => {
    setXvalue(
      listContainerRef.current.scrollWidth -
      listContainerRef.current.clientWidth
    );
    listContainerRef.current.scrollLeft = !isMobile
      ? ((scRatio - 1) * xValue) / 5
      : listContainerRef.current.scrollLeft;
  }, [scRatio, xValue]);

  const { t } = useTranslation();

  useEffect(() => {
    xCb();
    window.addEventListener("resize", xCb);
    return () => {
      window.removeEventListener("resize", xCb);
    };
  }, [xCb]);

  //Mobile device transition x value change
  useEffect(() => {
    if (isSize) setTransition(100);
    else setTransition(20);
  }, [isSize]);

  const styles = {
    a2: {
      opacity: scRatio > 0.55 ? "1" : scRatio < 0.3 ? "0" : (scRatio - 0.3) * 4,
      transform: `translateX(${scRatio > 0.55
          ? "0px"
          : scRatio < 0.3
            ? 100
            : `${100 - (scRatio - 0.3) * 100 * 4}px`
        })`,
    },
    a1: {
      opacity: scRatio > 0.55 ? "1" : scRatio < 0.3 ? "0" : (scRatio - 0.3) * 4,
      transform: `translateX(${scRatio > 0.55
          ? "0px"
          : scRatio < 0.3
            ? `${transition}px`
            : `-${transition - (scRatio - 0.3) * transition * 4}px`
        })`,
    },
    a3: {
      opacity: scRatio > 0.55 ? "1" : scRatio < 0.3 ? "0" : (scRatio - 0.3) * 4,
    },
  };

  return (
    <section
      ref={sectionRef}
      className={`${isMobile ? "h-[100vh]" : "h-[600vh]"
        } 2xl:mt-[calc(10vh--70px)] md:mt-[calc(10vh--40px)] mt-[calc(10vh--15px)]`}
    >
      <div className="h-screen 2xl:min-h-[866px] lg:min-h-[735px] md:min-h-[675px] min-h-[562px] sticky top-0">
        <div className="h-max flex flex-col justify-center overflow-hidden lg:pt-[110px] pt-20">
          <div
            className={`animated fadeInUp lg:max-w-[830px] max-w-[575px] mx-4 md:m-auto`}
            style={{ animationDelay: ".3s" }}
          >
            <H2_MD>
              <span className="block md:hidden" style={styles.a1}>
                {t("HOME.TITLE")}
              </span>
              <span className="block md:text-center md:flex md:justify-center" style={styles.a2}>
                <span className="hidden md:block">{(t("HOME.TITLE"))}&nbsp;</span>{t("HOME.TITLE2")}
              </span>
            </H2_MD>
            <P_2MD className="mt-3 md:text-center" style={styles.a3}>
              {t('HOME.DESCRIPTION')}
            </P_2MD>
          </div>
          <div
            className={`${isLoad ? "invisible" : "visible"} ${isMobile ? "overflow-auto" : "overflow-hidden"
              } pb-8 2xl:mt-10 mt-8 whitespace-nowrap h-scroll lg:px-120 md:px-10 px-4`}
            ref={listContainerRef}
          >
            <Box
              className={[
                `box-s1 animated ${scRatio > (isMobile ? 0.65 : 0.5)
                  ? "fadeInRight"
                  : "fadeOutRight"
                }`,
                "box-d1",
              ]}
              title={t("HOME.WEB3_TITLE")}
              content={t("HOME.WEB3_DESCRIPTION")}
              img="ic-web3-strategy.png"
              img2x="ic-web3-strategy@2x.png"
              pictureClass="box-icon-a1"
            />
            {/* <Box
              className={[
                `box-s2 animated ${scRatio > (isMobile ? 0.65 : 0.55)
                  ? "fadeInRight"
                  : "fadeOutRight"
                }`,
                "box-d2",
              ]}
              title="TOKENOMICS"
              content="Our expert team in tokenomics will create the right supply and demand characteristics of your branded token to match your project utilities, campaign goals, and the strategy of the project."
              img="ic-tokenomics.png"
              img2x="ic-tokenomics@2x.png"
              pictureClass="box-icon-a2"
            />
            <Box
              className={[
                `box-s1 animated ${scRatio > (isMobile ? 0.65 : 0.7)
                  ? "fadeInRight"
                  : "fadeOutRight"
                }`,
                "box-d3",
              ]}
              title="NFT CREATION"
              content="We design projects that suit your brand the most and help you to occupy a solid place in the web3 world with custom NFTs and tokens for your brand, building a whole new way of customer loyalty and engagement."
              img="ic-nft-creation.png"
              img2x="ic-nft-creation@2x.png"
              pictureClass="box-icon-a1"
            /> */}
            <Box
              className={[
                `box-s2 animated ${scRatio > (isMobile ? 0.65 : 0.8)
                  ? "fadeInRight"
                  : "fadeOutRight"
                }`,
                "box-d1",
              ]}
              title={t("HOME.COMMUNITY_TITLE")}
              content={t("HOME.COMMUNITY_DESCRIPTION")}
              img="ic-community.png"
              img2x="ic-community@2x.png"
              pictureClass="box-icon-a2"
            />
            <Box
              className={[
                `box-s1 animated ${scRatio > (isMobile ? 0.65 : 0.9)
                  ? "fadeInRight"
                  : "fadeOutRight"
                }`,
                "box-d2",
              ]}
              title={t("HOME.FINTECH_TITLE")}
              content={t("HOME.FINTECH_DESCRIPTION")}
              img="ic-fintech-integration.png"
              img2x="ic-fintech-integration@2x.png"
              pictureClass="box-icon-a1"
            />
            <Box
              className={[
                `box-s2 animated ${scRatio > (isMobile ? 0.65 : 0.95)
                  ? "fadeInRight"
                  : "fadeOutRight"
                }`,
                "box-d3",
              ]}
              title={t("HOME.GAMIFICATION_TITLE")}
              content={t("HOME.GAMIFICATION_DESCRIPTION")}
              img="ic-gamification.png"
              img2x="ic-gamification@2x.png"
              pictureClass="box-icon-a2"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default memo(Development);
