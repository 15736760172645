import { H2_MD, P_SM } from "../../Typography";
import "./SuccessModal.scss";
import SuccessCheckIcon from "../../../assets/images/ic-modal-success.png";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";

const SuccessModalContainer = ({ disposeModal, title, description }, ref) => {

  const { t } = useTranslation();

  return (
    <>
      <div
        ref={ref}
        className="successModal-container md:px-10 md:py-8 p-4 overflow-y-auto animated zoomIn"
        style={{ animationDelay: "0.5s" }}
      >
        <button
          className="absolute md:right-10 right-3 md:top-8 top-3 successModal-close"
          onClick={() => disposeModal(ref)}
        >
          <span></span>
          <span></span>
        </button>
        <H2_MD className="lg:!text-[68px] lg:!leading-[60px] text-center">
          {title}
        </H2_MD>
        <P_SM className="text-center text-silver-sand lg:leading-6 leading-4">
          {description}
        </P_SM>
        <img src={SuccessCheckIcon} alt="" className="mx-auto my-8 w-[200px]" />
        <button
          className="successModal-send-btn text-center w-full font-heading md:h-[75px] h-12 md:rounded-2xl rounded-xl md:text-xl text-2xl-m text-black relative top-0 duration-300 hover:top-[-3px] outline-0"
          type="submit"
          onClick={() => disposeModal(ref)}
        >
          {t("FORM.WAITLIST.SUCCESS.BUTTON")}
        </button>
      </div>
    </>
  );
};

export default forwardRef(SuccessModalContainer);
