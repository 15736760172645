
export const apply = {
  api: process.env.REACT_APP_WAITLIST_API,
  loading: "Submit Form...",
  success: "Submission Successful!",
  error: "Submission Failed!",
  validation: {
    email: "Invalid Email!",
  },
  required: {
    email: "Email Required!",
  },
};

export const waitlist = {
  api: process.env.REACT_APP_WAITLIST_API,
  loading: "Submit Form...",
  success: "Submission Successful!",
  error: "Submission Failed!",
  validation: {
    email: "Invalid Email!",
  },
  required: {
    email: "Email Required!",
  },
};

export const subscribe = {
  api: process.env.REACT_APP_SUBSCRIBE_API,
  loading: "Submit Form...",
  success: "Submission Successful!",
  error: "Submission Failed!",
  validation: {
    email: "Invalid Email!",
  },
  required: {
    email: "Email Required!",
  },
};

export const investwithus = {
  api: process.env.REACT_APP_INVESTWITHUS_API,
  loading: "Submit Form...",
  success: "Submission Successful!",
  error: "Submission Failed!",
  min: "Too Short!",
  max: "Too Long!",
  validation: {
    firstName: "Invalid First Name!",
    lastName: "Invalid Last Name!",
    email: "Invalid Email!",
    investorType: "Invalid Investor Type!",
    highNetWorth: "Invalid High Net Worth!",
    phone: "Invalid Phone Number!",
    investmentAmount: "Invalid Investment Amount!",
    citizenship: "Invalid Country!",
    message: "Invalid Message!",
  },
  required: {
    firstName: "First Name Required!",
    lastName: "Last Name Required!",
    email: "Email Required!",
    investorType: "Investor Type Required!",
    highNetWorth: "High Net Worth Required!",
    investmentAmount: "Investment Amount Required!",
    phone: "Phone Number Required!",
    citizenship: "Country Required!",
    message: "Message Required!",
  },
};
