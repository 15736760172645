import React, { useLayoutEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, useLocation } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import App from "./App";
import "./assets/scss/global.scss";
import i18n from "i18next";
import { initReactI18next } from 'react-i18next';

const root = ReactDOM.createRoot(document.getElementById("root"));
const userLanguage = navigator.language.split('-')[0];
const language = localStorage.getItem('language');

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: require('./locales/en/translation.json')
    },
    tr: {
      translation: require('./locales/tr/translation.json')
    }
  },
  lng: language ? language : userLanguage === 'en' ? 'en' : 'tr',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

const Wrapper = ({ children }) => {
  const location = useLocation();
  const [lock, setLock] = useState(false);
  useLayoutEffect(() => {
    lock &&
      setTimeout(() => {
        document.documentElement.scrollTo(0, 0);
      }, 300);
    setLock(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
  return children;
};

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Wrapper>
        <App />
      </Wrapper>
    </BrowserRouter>
  </Provider>
);
