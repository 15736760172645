import { memo, useRef } from "react";
import { H2_LG } from "../../../components/Typography";
import { useScrollAnimate } from "../../../hooks/useScrollAnimate";
import Background from "../../../assets/images/our-projects-bg.jpg";
import useChangeWidth from "../../../hooks/useChangeWidth";
import Benofity from "../../../assets/images/logo-benofity.svg";
import Winaged from "../../../assets/images/logo-winaged.svg";
import CovenlabsClub from "../../../assets/images/logo-covenlabsclub.svg";
import WatchersCoven from "../../../assets/images/logo-watchers-coven.svg";
import Budyboo from "../../../assets/images/logo-budyboo.svg";
import Definx from "../../../assets/images/logo-definx.svg";
import Looyti from "../../../assets/images/logo-looyti.svg";
import Blockchaintime from "../../../assets/images/logo-blockchain.svg";
import Obaven from "../../../assets/images/logo-obaven.svg";
import { useTranslation } from "react-i18next";

const Projects = ({ children, href }) => {

  return (
    <>
      <a
        className="project-box block xl:w-[150px] xl:h-[150px] sm:w-[130px] sm:h-[130px] w-[110px] h-[110px] rounded-full relative duration-300 top-0 hover:-top-2"

        // eslint-disable-next-line no-script-url
        href={href === "#" ? "javascript:;" : href}
        target={href !== "#" ? "_blank" : null}
        rel="noreferrer"
      >
        <span className="flex justify-center items-center w-full h-full px-3">
          {children}
        </span>
      </a>
    </>
  );
};

const OurProjects = () => {
  const sectionRef = useRef();
  const isSize = useChangeWidth(1024);
  const scRatio = useScrollAnimate({
    ref: sectionRef,
    scenes: 3,
  });

  const styles = {
    bg: {
      opacity: scRatio >= 1 ? "1" : scRatio < 0.5 ? "0" : (scRatio - 0.5) * 2,
      transform: `scale(${scRatio >= 1 ? "1" : scRatio < 0.5 ? "0" : (1.5 - scRatio) * 2
        }) rotate(${isSize
          ? scRatio >= 1
            ? "360"
            : scRatio < 0.5
              ? "0"
              : (scRatio - 0.5) * 360 * 2
          : 0
        }deg)`,
    },
    a1: {
      opacity: scRatio >= 1.25 ? "1" : scRatio < 1 ? "0" : (scRatio - 1) * 4,
      transform: `translateY(${scRatio >= 1.5
        ? "0px"
        : scRatio < 1.25
          ? "50%"
          : `${50 - (scRatio - 1.25) * 200}%`
        })`,
    },
    a2: {
      opacity:
        scRatio >= 1.5 ? "1" : scRatio < 1.25 ? "0" : (scRatio - 1.25) * 4,
      transform: `translateY(${scRatio >= 1.5
        ? "0px"
        : scRatio < 1.25
          ? "50%"
          : `${50 - (scRatio - 1.25) * 200}%`
        })`,
    },
    projects: {
      a1: {
        transform: `scale(${scRatio >= 1.6 ? "1" : scRatio < 1.4 ? "0" : (scRatio - 1.4) * 5
          })`,
      },
      a2: {
        transform: `scale(${scRatio >= 1.7 ? "1" : scRatio < 1.5 ? "0" : (scRatio - 1.5) * 5
          })`,
      },
      a3: {
        transform: `scale(${scRatio >= 1.8 ? "1" : scRatio < 1.6 ? "0" : (scRatio - 1.6) * 5
          })`,
      },
      a4: {
        transform: `scale(${scRatio >= 1.9 ? "1" : scRatio < 1.7 ? "0" : (scRatio - 1.7) * 5
          })`,
      },
      a5: {
        transform: `scale(${scRatio >= 2 ? "1" : scRatio < 1.8 ? "0" : (scRatio - 1.8) * 5
          })`,
      },
      a6: {
        transform: `scale(${scRatio >= 2.1 ? "1" : scRatio < 1.9 ? "0" : (scRatio - 1.9) * 5
          })`,
      },
      a7: {
        transform: `scale(${scRatio >= 2.2 ? "1" : scRatio < 2 ? "0" : (scRatio - 2) * 5
          })`,
      },
      a8: {
        transform: `scale(${scRatio >= 2.3 ? "1" : scRatio < 2.1 ? "0" : (scRatio - 2.1) * 5
          })`,
      },
      a9: {
        transform: `scale(${scRatio >= 2.4 ? "1" : scRatio < 2.2 ? "0" : (scRatio - 2.2) * 5
          })`,
      },
    },
  };

  const { t } = useTranslation();

  return (
    <section
      id="ourProjects"
      ref={sectionRef}
      className="h-[200vh] bg-black z-10 relative"
    >
      <div className="h-screen xl:min-h-[722px] lg:min-h-[666px] md:min-h-[526px] min-h-[592px] sticky top-0">
        <div className="h-full flex flex-col justify-center items-center overflow-hidden lg:pt-[110px] pt-20 pb-5 lg:px-120 md:px-10 px-4">
          <div className="text-center xl:max-w-[830px] max-w-[690px]">
            <H2_LG>
              <span className="flex justify-center" style={styles.a1}>
                {t("OUR_PROJECTS")}
              </span>
            </H2_LG>
            <div className="flex flex-wrap lg:gap-6 sm:gap-5 gap-2 justify-center max-w-[543px] mx-auto lg:mt-8 mt-10 sm:w-full w-96">
              <div style={styles.projects.a1}>
                <Projects href={"https://budyboo.com/"}>
                  <img src={Budyboo} alt="Budyboo" />
                </Projects>
              </div>
              <div style={styles.projects.a8}>
                <Projects href={"https://benofity.com/"}>
                  <img src={Benofity} alt="Benofity" />
                </Projects>
              </div>
              <div style={styles.projects.a2}>
                <Projects href={"https://covenlabsclub.xyz/"}>
                  <img src={CovenlabsClub} alt="Covenlabs CLub" />
                </Projects>
              </div>
              <div style={styles.projects.a6}>
                <Projects>
                  <img src={Looyti} alt="Looyti" />
                </Projects>
              </div>
              <div style={styles.projects.a3}>
                <Projects>
                  <img src={WatchersCoven} alt="Watchers Coven" />
                </Projects>
              </div>
              <div style={styles.projects.a7}>
                <Projects href={"https://winaged.com/"}>
                  <img src={Winaged} alt="Winaged" />
                </Projects>
              </div>
              <div style={styles.projects.a4}>
                <Projects>
                  <img src={Obaven} alt="Obaven" />
                </Projects>
              </div>
              <div style={styles.projects.a9}>
                <Projects>
                  <img src={Blockchaintime} alt="Blockchaintime" />
                </Projects>
              </div>
              <div style={styles.projects.a5}>
                <Projects href={"https://definx.io/"}>
                  <img src={Definx} alt="Definx" />
                </Projects>
              </div>
            </div>
          </div>
        </div>
        <div className="h-screen absolute top-0 left-0 right-0 w-full overflow-hidden -z-10 min-w-[375px]">
          <img
            src={Background}
            alt=""
            style={styles.bg}
            className="absolute top-0 left-0 object-cover h-full w-full"
          />
        </div>
      </div>
    </section>
  );
};

export default memo(OurProjects);
