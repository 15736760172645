import { useTranslation } from "react-i18next";
import image1 from "../../assets/images/ic-progress1.png";
import image2 from "../../assets/images/ic-progress2.png";
import image3 from "../../assets/images/ic-progress3.png";
import image4 from "../../assets/images/ic-progress4.png";
import image5 from "../../assets/images/ic-progress5.png";
import { P_2MD } from "../../components/Typography";
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import { memo, useEffect, useState, useCallback } from 'react';
import { useInView } from "react-intersection-observer";
import './OfferingBox.scss'

const OfferingBox = ({ contentData }) => {
    const [percentage, setPercentage] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [isClickProcessing, setIsClickProcessing] = useState(false);
    const [animationStop, setAnimationStop] = useState(true);
    const { ref, inView } = useInView({ threshold: 1 });
    const [touchStart, setTouchStart] = useState(0);
    const { t } = useTranslation();

    const images = ['', image1, image2, image3, image4, image5];

    const styles = {
        a3: {
            fontFamily: 'Bebas Neue',
            fontSize: '20px',
            fontWeight: 400,
            lineHeight: '24px',
            letterSpacing: '0em',
            textAlign: 'start',
            color: `rgba(255, 255, 255, 0.2)`,
            transition: "all 1s",
        },

        highlight: {
            color: `rgba(255, 255, 255, 1)`,
        },

        progress: {
            height: `calc(${percentage}% - 10px)`,
            maxHeight: `calc(100% - 10px)`,
            transition: "all 1s",
            background: "rgba(255, 255, 255, 0.5)",
            width: "3px",
            minHeight: "3px",
            borderRadius: "20px",
            marginRight: "10px",
            marginTop: "10px",
        },

        full: {
            boxShadow: '0 0 10px rgba(127, 127, 177, 0.8)',
            borderRadius: '50%',
            marginRight: "110px",
        }
    };

    const handleTextClick = (index) => {
        if (!isClickProcessing) {
            setIsClickProcessing(true);
            setCurrentIndex(index);
        }
        setTimeout(() => {
            setIsClickProcessing(false);
        }, 1500);
    };

    useEffect(() => {
        if (inView) {
            document.documentElement.style.overscrollBehavior = "none";
        } else {
            document.documentElement.style.overscrollBehavior = "auto";
        }
    }, [inView]);


    useEffect(() => {
        if (window.scrollY > 25) {
            setCurrentIndex(5);
            document.documentElement.classList.remove("overflow-hidden");
        }

        else {
            document.documentElement.classList.add("overflow-hidden");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [window.scrollY]);


    useEffect(() => {
        setPercentage(currentIndex * 20);
        if (currentIndex >= 5) {
            setPercentage(100);
        }
    }, [currentIndex]);


    const debounce = (func, delay) => {
        let timeoutId;
        return (...args) => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => {
                func(...args);
            }, delay);
        };
    };

    const debouncedHandleScroll = debounce((scrollDirection) => {
        if (scrollDirection === "down") {
            if (currentIndex < 5) {
                setCurrentIndex((prev) => prev + 1);
            } else {
                setCurrentIndex(5);
            }
        } else {
            if (currentIndex > 0) {
                if (window.scrollY < 25) {
                    setCurrentIndex((prev) => prev - 1);
                }
            } else {
                setCurrentIndex(0);
            }
        }
    }, 100);

    const handleScroll = useCallback((e) => {
        e.preventDefault();

        let scrollDirection = e.deltaY > 0 ? "down" : "up";

        if (animationStop) {
            setAnimationStop(false);
            debouncedHandleScroll(scrollDirection);
        }

        if (currentIndex === 5) {
            document.documentElement.classList.remove("overflow-hidden");
        } else if (currentIndex < 5 && window.scrollY < 25) {
            document.documentElement.classList.add("overflow-hidden");
        } else {
            document.documentElement.classList.add("overflow-hidden");
        }

        setTimeout(() => {
            setAnimationStop(true);
        }, 1500);
    }, [animationStop, currentIndex, debouncedHandleScroll]);

    const handleTouchStart = (e) => {
        setTouchStart(e.changedTouches[0].screenY);
        document.documentElement.classList.add("overflow-hidden");
    };

    const handleTouchEnd = useCallback((e) => {
        const touchEnd = e.changedTouches[0].screenY;

        if (currentIndex === 5) {
            document.documentElement.classList.remove("overflow-hidden");
        } else {
            document.documentElement.classList.add("overflow-hidden");
        }

        if (animationStop) {
            setAnimationStop(false);
            if (touchStart > touchEnd) {
                if (currentIndex < 5) {
                    setCurrentIndex((prevIndex) => prevIndex + 1);
                } else {
                    setCurrentIndex(5);
                }
            } else {
                if (currentIndex > 0) {
                    setCurrentIndex((prevIndex) => prevIndex - 1);
                } else {
                    setCurrentIndex(0);
                }
            }
        }

        setTimeout(() => {
            setAnimationStop(true);
        }, 1500);
    }, [currentIndex, touchStart, animationStop]);

    useEffect(() => {
        if (animationStop && inView) {
            window.addEventListener('wheel', handleScroll, { passive: false });
            window.addEventListener("touchstart", handleTouchStart, { passive: false });
            window.addEventListener("touchend", handleTouchEnd, { passive: false });
        } else {
            window.removeEventListener('wheel', handleScroll);
            window.removeEventListener("touchstart", handleTouchStart);
            window.removeEventListener("touchend", handleTouchEnd);
        }

        return () => {
            window.removeEventListener('wheel', handleScroll);
            window.removeEventListener("touchstart", handleTouchStart);
            window.removeEventListener("touchend", handleTouchEnd);
        };
    }, [animationStop, handleTouchEnd, handleScroll, inView]);

    useEffect(() => {
        const barIcon = document.getElementById("barIcon");
        const barTitle = document.getElementById("barTitle");
        const barText = document.getElementById("barText");

        barIcon.classList.add("animated-long", "fadeIn");
        barTitle.classList.add("animated-long", "fadeIn");
        barText.classList.add("animated-long", "fadeIn");

        setTimeout(() => {
            barIcon.classList.remove("animated-long", "fadeIn");
            barTitle.classList.remove("animated-long", "fadeIn");
            barText.classList.remove("animated-long", "fadeIn");
        }, 1500);
    }, [currentIndex]);


    return (
        <div style={{ touchAction: currentIndex !== 5 ? 'none' : 'unset' }} ref={ref}>
            <CircularProgressbarWithChildren
                className="bar"
                value={percentage}
                styles={{
                    ...buildStyles({
                        strokeLinecap: 'round',
                        pathTransitionDuration: 1,
                        pathColor: `rgba(127, 127, 177, 1), ${percentage / 100})`,
                    }),
                    ...styles.full,
                }}
                strokeWidth={0.8}
            >
                <div className="flex flex-col items-center gap-y-2 md:gap-y-4 w-full">
                    <img alt="" className={`${currentIndex <= 0 ? '!hidden' : ''} process-icon`} id="barIcon" src={images[currentIndex]} />
                    <span id="barTitle" className="process-title">{contentData[currentIndex]?.title}</span>
                    <span id="barText" className="process-text">{contentData[currentIndex]?.text}</span>
                </div>
            </CircularProgressbarWithChildren>
            <div className="hidden xl:flex self-end w-fit absolute bottom-0 mb-8 left-7">
                <div>
                    <div style={styles.progress}></div>
                </div>
                <div className="flex flex-col">
                    <button
                        onClick={() => handleTextClick(1)}
                        style={{ cursor: isClickProcessing ? 'default' : 'pointer' }}
                    >
                        <P_2MD className="mt-3" style={currentIndex === 1 ? { ...styles.a3, ...styles.highlight } : styles.a3}>
                            <span>{t("HOME.OFFERINGS.TITLE1")}</span>
                        </P_2MD>
                    </button>
                    <button
                        onClick={() => handleTextClick(2)}
                        style={{ cursor: isClickProcessing ? 'default' : 'pointer' }}
                    >
                        <P_2MD className="mt-3" style={currentIndex === 2 ? { ...styles.a3, ...styles.highlight } : styles.a3}>
                            <span>{t("HOME.OFFERINGS.TITLE2")}</span>
                        </P_2MD>
                    </button>
                    <button
                        onClick={() => handleTextClick(3)}
                        style={{ cursor: isClickProcessing ? 'default' : 'pointer' }}
                    >
                        <P_2MD className="mt-3" style={currentIndex === 3 ? { ...styles.a3, ...styles.highlight } : styles.a3}>
                            <span>{t("HOME.OFFERINGS.TITLE3")}</span>
                        </P_2MD>
                    </button>
                    <button
                        onClick={() => handleTextClick(4)}
                        style={{ cursor: isClickProcessing ? 'default' : 'pointer' }}
                    >
                        <P_2MD className="mt-3" style={currentIndex === 4 ? { ...styles.a3, ...styles.highlight } : styles.a3}>
                            <span>{t("HOME.OFFERINGS.TITLE4")}</span>
                        </P_2MD>
                    </button>
                    <button
                        onClick={() => handleTextClick(5)}
                        style={{ cursor: isClickProcessing ? 'default' : 'pointer' }}
                    >
                        <P_2MD className="mt-3" style={currentIndex === 5 ? { ...styles.a3, ...styles.highlight } : styles.a3}>
                            <span>{t("HOME.OFFERINGS.TITLE5")}</span>
                        </P_2MD>
                    </button>
                </div>
            </div>
        </div>
    )
}
export default memo(OfferingBox);