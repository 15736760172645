import { useCallback, useLayoutEffect, useState } from "react";

export const useScrollAnimate = (options) => {
  const [animateRatio, setAnimateRatio] = useState(0);

  const onScroll = useCallback(() => {
    if (options.ref.current === undefined) return;

    const clientHeight = options.ref.current.clientHeight;
    const offsetTop = options.ref.current.offsetTop;
    const scenesCount = options.scenes;
    const scenesHeight = clientHeight / scenesCount;
    const scenesCenter = scenesHeight;

    const start = window.pageYOffset + scenesCenter > offsetTop;
    const animateValue = (window.pageYOffset - offsetTop) / scenesCenter + 1;

    // resize olduğunda devamlı "animateValue" tekrar hesaplanması gerektiği için bu kontrol yorum satırına alındı
    if (start && animateValue < scenesCount) {
      setAnimateRatio(animateValue.toFixed(2));
    }
  }, [options.ref, options.scenes]);

  useLayoutEffect(() => {
    onScroll();
    window.addEventListener("scroll", onScroll);
    window.addEventListener("resize", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
      window.removeEventListener("resize", onScroll);
    };
  }, [onScroll]);

  return parseFloat(animateRatio);
};
