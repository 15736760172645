import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIntroStatus } from "../../features/intro/IntroSlice";
import { useScrollAnimate } from "../../hooks/useScrollAnimate";
import Development from "./sections/Development";
import LaunchPad from "./sections/LaunchPad";
import Intro from "../../features/intro/Intro";
import Web3Fund from "./sections/Web3Fund";
import Ecosystem from "./sections/Ecosystem";
import CovenlabsClub from "./sections/CovenlabsClub";
import OurProjects from "./sections/OurProjects";
import IconRight from "../../assets/images/ic-right-arrow.svg";
import { useTranslation } from "react-i18next";

import "./About.scss";
import Offerings from "./sections/Offerings";

const About = () => {
  const [filter, setFilter] = useState(0);
  const scroll = useRef();
  const { introComplete } = useSelector((state) => state.intro);
  const webRef = useRef();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  //web3Fund içeriğinde background video parallax
  const webScRatio = useScrollAnimate({
    ref: webRef,
    scenes: 2,
  });

  //sayfa kaydığında video üzerindeki karartması
  const isFilter = () => {
    scroll.current = window.pageYOffset;
    if (scroll.current < window.innerHeight / 2.7)
      setFilter(window.pageYOffset / (window.innerHeight / 2));
    else setFilter(0.75);
  };

  const skipToIntro = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      dispatch(setIntroStatus(true));
    }, 1300);
  };

  useEffect(() => {
    isFilter();
    window.addEventListener("scroll", isFilter);
    return () => {
      window.removeEventListener("scroll", isFilter);
    };
  }, [filter]);

  const styles = {
    filter: {
      filter: `brightness(${1 - filter})`,
      transform: `translateY(${webScRatio <= 0.1
          ? "0"
          : webScRatio > 0.1 && webScRatio < 1
            ? `-${(webScRatio - 0.1) * 50}%`
            : "-100%"
        })`,
    },
  };

  return (
    <>
      {introComplete && !loading ? (
        <>
          <Offerings />
          <Development />
          <LaunchPad />
          <Ecosystem />
          <CovenlabsClub />
          <OurProjects />
          <Web3Fund ref={webRef} />
        </>
      ) : null}
      <Intro style={styles.filter} skipToIntro={loading} />
      {!introComplete && (
        <div className="fixed bottom-0 left-0 w-full min-w-[375px]">
          <div className="arrow md:bottom-10 bottom-8 md:right-10 right-7 absolute pt-10 font-heading text-lg">
            {t("INTRO.SCROLL")}
          </div>
          <button
            onClick={skipToIntro}
            className="font-heading text-sm w-36 absolute md:bottom-10 bottom-8 md:left-10 left-7 flex items-center group lg:opacity-50 lg:hover:opacity-100 duration-300"
          >
            <img
              src={IconRight}
              className="mr-3 mb-1 group-hover:mr-2 duration-300"
              alt=""
            />
            <span className="block">{t("INTRO.SKIP")}</span>
          </button>
        </div>
      )}
    </>
  );
};

export default About;
