import { useEffect, useState } from "react";

export function usePageLoad() {
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setFirstLoad(false);
    }, 500);
  }, []);

  return firstLoad;
}
