import { useEffect, useRef, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import useChangeWidth from "../../hooks/useChangeWidth";
import { usePageLoad } from "../../hooks/usePageLoad";
import "./Header.scss";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const LI = (props) => {
  return (
    <>
      <li className={`md:text-[22px] md:leading-6 text-[54px] leading-[74px]`}>
        <NavLink
          end={props.end}
          to={props.to}
          className={({ isActive }) => (isActive ? "active" : null)}
        >
          {props.title}
        </NavLink>
      </li>
    </>
  );
};

const Header = () => {
  const [scrollY, setScrollY] = useState(true);
  const scroll = useRef();
  const location = useLocation();
  const timeout = { enter: 300, exit: 0 };
  const isLoad = usePageLoad();
  const isSize = useChangeWidth(768);
  const navigate = useNavigate();

  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(i18n.language);
  const [isActive, setIsActive] = useState(false);

  const toggleLanguage = () => {
    const newLanguage = currentLanguage === 'en' ? 'tr' : 'en';
    setCurrentLanguage(newLanguage);
    i18n.changeLanguage(newLanguage);
    setIsActive(!isActive)
    localStorage.setItem('language', newLanguage);
  };

  //sayfa kaydığında mini headerın gelmesi
  const isScroll = () => {
    scroll.current = window.pageYOffset;
    if (scroll.current > 50) setScrollY(false);
    else setScrollY(true);
  };


  useEffect(() => {
    if (isSize) {
      isScroll();
      window.addEventListener("scroll", isScroll);
    } else window.removeEventListener("scroll", isScroll);
    return () => {
      window.removeEventListener("scroll", isScroll);
    };
  }, [isSize]);

  const idPositionScroll = (id, screen = 0) => {
    const section = document.getElementById(id);
    if (section) {
      window.scrollTo({
        top: section.offsetTop + window.innerHeight * screen,
        behavior: "smooth",
      });
    } else {
      navigate("/");
      setTimeout(() => {
        const section = document.getElementById(id);
        window.scrollTo({
          top: section.offsetTop + window.innerHeight * screen,
          behavior: "smooth",
        });
      }, 400);
    }
  };

  return (
    <>
      <TransitionGroup>
        <CSSTransition
          key={location.pathname}
          classNames="header"
          timeout={timeout}
          unmountOnExit
          appear
        >
          <header
            className={`header lg:backdrop-blur-0 backdrop-blur ${isLoad ? "animated fadeInDown" : ""
              } fixed flex flex-col justify-center w-full min-w-[375px] left-0 duration-500 z-40 ${!scrollY ? "header-fixed top-0 !backdrop-blur" : "lg:top-9 "
              } lg:h-[110px] h-[80px]`}
          >
            <div className="lg:px-120 md:px-10 px-4 md:h-auto h-8 w-full flex justify-between items-center relative z-10 select-none">
              <Link to="/" reloadDocument>
                <img
                  className="duration-500 lg:w-[170px] w-[97px]"
                  src={require(`../../assets/images/logo.png`)}
                  alt="Covenlabs"
                  srcSet={`${require(`../../assets/images/logo.png`)} 1x, ${require(`../../assets/images/logo@2x.png`)} 2x`}
                />
              </Link>
              <div className='ml-auto mr-4 block md:hidden switch-border'>
                <div className={`switch ${currentLanguage === 'tr' ? 'active' : ''}`} onClick={toggleLanguage}>
                  <div className="slider"></div>
                  <div className="en">EN</div>
                  <div className="tr">TR</div>
                </div>
              </div>
              <input
                className="mobile-menu-input block md:hidden w-10 h-8 absolute md:right-8 right-2 cursor-pointer z-20 top-[-3px] opacity-0"
                type="checkbox"
              />
              <div className="mobile-menu-btn relative z-10 select-none flex flex-col items-end md:duration-[0s] duration-500 md:scale-0 scale-100">
                <span></span>
                <span></span>
                <span className="!mb-0"></span>
              </div>
              <ul className="main-menu md:visible invisible md:flex xl:gap-14 md:gap-8 gap-0 md:relative absolute md:translate-x-0 translate-x-full md:p-0 md:px-0 px-4 pt-24 pb-5 md:h-auto h-screen md:top-0 top-[-24px] md:w-auto w-full md:bg-transparent bg-black -z-10 right-0">
                <LI title={t('HEADER.HOME')} to="/" end />
                {/* <LI title="COVENLABS CLUB" to="/covenlabs-club" /> */}
                {/* <LI title="RECENT PROJECTS" to="/recent-projects" /> */}
                <li className="md:text-[22px] md:leading-6 text-[54px] leading-[74px]">
                  <a
                    href="https://covenlabsclub.xyz/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    COVENLABS CLUB
                  </a>
                </li>
                <li
                  className={`md:text-[22px] md:leading-6 text-[54px] leading-[74px] opacity-[.65] cursor-pointer`}
                  onClick={() => idPositionScroll("ourProjects", 1)}
                >
                  {t('HEADER.PROJECTS')}
                </li>
                <LI title={t('HEADER.CAREER')} to="/career" />
                <LI title={t('HEADER.MEDIA')} to="/media" />
                <div className='hidden md:block switch-border'>
                  <div className={`switch ${currentLanguage === 'tr' ? 'active' : ''}`} onClick={toggleLanguage}>
                    <div className="slider"></div>
                    <div className="en">EN</div>
                    <div className="tr">TR</div>
                  </div>
                </div>
              </ul>
            </div>
          </header>
        </CSSTransition>
      </TransitionGroup>
    </>
  );
};

export default Header;
