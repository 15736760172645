import { Field } from "formik";

const InvestInput = ({
  name,
  type,
  placeholder,
  as,
  className,
  children,
  style,
  onKeyUp,
}) => {
  return (
    <>
      <div className={className} style={{ ...style }}>
        <Field
          name={name}
          type={type}
          placeholder={placeholder}
          as={as}
          className={`investform-input bg-black outline-0 w-full md:py-4 py-3 md:px-7 px-4 md:rounded-2xl rounded-xl md:text-base text-base-m ${
            as === "textarea" ? "resize-none md:h-20 h-15" : "md:h-[55px] h-11"
          }`}
          onKeyUp={onKeyUp}
        />
        {children}
      </div>
    </>
  );
};

export default InvestInput;
