import { H2_MD, P_2MD, H3_MD } from "../../../components/Typography";
import { useScrollAnimate } from "../../../hooks/useScrollAnimate";
import { memo, useEffect, useRef } from "react";
import video from "../../../assets/video/ecosystem.mp4";
import videoMobile from "../../../assets/video/ecosystem-mobile.mp4";
import useChangeWidth from "../../../hooks/useChangeWidth";
import poster from "../../../assets/images/ecosystem-poster.jpg";
import posterMobile from "../../../assets/images/ecosystem-poster-mobile.jpg";
import { useTranslation } from "react-i18next";

const Ecosystem = () => {
  const sectionRef = useRef();
  const padRef = useRef();
  const videoRef = useRef();
  const isMobile = useChangeWidth(1024);
  const scRatio = useScrollAnimate({
    ref: sectionRef,
    scenes: 2,
  });

  const {t} = useTranslation();

  const styles = {
    a1: {
      opacity: scRatio >= 1 ? "1" : scRatio < 0.5 ? "0" : scRatio - 0.5,
      transform: `${isMobile ? "translateX" : "translateY"}(${
        scRatio >= 1
          ? "0px"
          : scRatio < 0.5
          ? "50%"
          : `-${50 - (scRatio - 0.5) * 100}%`
      })`,
    },
    a2: {
      opacity: scRatio >= 1 ? "1" : scRatio < 0.5 ? "0" : scRatio - 0.5,
      transform: `${isMobile ? "translateX" : "translateY"}(${
        scRatio >= 1
          ? "0px"
          : scRatio < 0.5
          ? "50%"
          : `${50 - (scRatio - 0.5) * 100}%`
      })`,
    },
    a3: {
      opacity: scRatio >= 1 ? "1" : scRatio < 0.5 ? "0" : scRatio - 0.5,
      transform: `translateY(${
        scRatio >= 1
          ? "0px"
          : scRatio < 0.5
          ? "50%"
          : `${50 - (scRatio - 0.5) * 100}%`
      })`,
    },
  };

  useEffect(() => {
    if (scRatio > 1 && scRatio < 1.95) videoRef.current.play();
    else videoRef.current.pause();
  }, [scRatio]);

  return (
    <>
      <section ref={sectionRef} className="h-[200vh] md:mt-[350px] mt-10">
        <div className="h-screen sticky top-0 min-h-[650px]">
          <div className="h-full flex flex-col lg:justify-center lg:text-left text-center overflow-hidden xl:pt-[110px] md:pt-31 pt-20">
            <div ref={padRef} className="lg:mx-120 md:mx-10 mx-4">
              <div className="sm:max-w-[450px] lg:mx-0 mx-auto">
                <H2_MD>
                  <span className="block" style={styles.a1}>
                    {t("ECOSYSTEM.TITLE")}
                  </span>
                </H2_MD>
                <H3_MD style={styles.a2}>{t("ECOSYSTEM.DESCRIPTION")}</H3_MD>
                <P_2MD className="mt-3 text-white" style={styles.a3}>
                {t("ECOSYSTEM.DESCRIPTION2")}
                </P_2MD>
              </div>
            </div>
          </div>
          <video
            ref={videoRef}
            className="w-full h-screen absolute object-cover top-0 left-0 -z-10"
            src={isMobile ? video : videoMobile}
            playsInline
            muted
            loop
          ></video>
          <img
            src={isMobile ? poster : posterMobile}
            alt=""
            className="w-full h-screen absolute object-cover top-0 left-0 -z-20"
          />
        </div>
      </section>
    </>
  );
};

export default memo(Ecosystem);
