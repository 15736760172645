import { ALink } from "../../components/form/button/Button";
import { H2_LG, H2_MD, H3_MD, P_SM } from "../../components/Typography";
import Arrow from "../../assets/images/ic-arrow.svg";
import SocialMedia from "../../components/SocialMedia";
import Background from "../../assets/images/media-bg.jpg";
import PressRelease1 from "../../assets/images/press-release-1@2x.jpg";
import NewsAA from "../../assets/images/news-aa.jpg";
import NewsAksam from "../../assets/images/news-aksam.jpg";
import NewsAnka from "../../assets/images/news-anka.jpg";
import NewsCointrust from "../../assets/images/news-cointrust.jpg";
import NewsGearrice from "../../assets/images/news-gearrice.jpg";
import NewsIha from "../../assets/images/news-iha.jpg";
import NewsPosta from "../../assets/images/news-posta.jpg";
import "./Media.scss";
import { useEffect, useState } from "react";
import Slider from "react-slick";
//import { readingTime } from "reading-time-estimator";
import { useTranslation } from "react-i18next";


const MediaBox = ({ title, description, img, href, slide, minutes }) => (
  <div className="mt-5">
    <a
      className={`${slide === true ? "w-full" : ""} media-box`}
      href={href}
      target="blank"
    >
      <span className="media-box--content">
        <H3_MD className="md:tracking-normal tracking-tight">{title}</H3_MD>
        <P_SM className="!text-base-m text-turquoise-blue">{description}</P_SM>
        {minutes &&
          <P_SM className="pt-2 !text-base-m text-indigo-400">{`${minutes} min read`}</P_SM>}
      </span>
      <img src={img} alt={title} />
    </a>
  </div>
);




const Media = () => {
  const { t } = useTranslation();
  const [tab, setTab] = useState(t('MEDIA.LANG') === 'en');
  // const [blogs, setBlogs] = useState([])
  // const [readingMin, setReadingMin] = useState([])
  // let id = ""
  // let tempReadingMin = [];


  // const baseUrl = process.env.REACT_APP_BASE_URL
  // const requestHeaders = new Headers({
  //   'Authorization': 'Bearer ' + process.env.REACT_APP_AUTH_TOKEN,
  // })
  // function getText(html) {
  //   var divContainer = document.createElement("div");
  //   divContainer.innerHTML = html;
  //   return divContainer.textContent || divContainer.innerText || "";
  // }
  // useEffect(() => {
  //   fetch(baseUrl + '/api/blogs?populate=*', {
  //     method: 'GET',
  //     headers: requestHeaders,
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setBlogs(data?.data)
  //       data?.data?.map((blog) => {
  //         id = blog.id;
  //         const result = readingTime(getText(blog.attributes?.content), 170, 'tr').minutes;
  //         const adjustedReadingTime = result === 0 ? 1 : result;
  //         tempReadingMin.push(adjustedReadingTime)
  //       })
  //       setReadingMin(tempReadingMin)

  //     })
  //     .catch((error) => {
  //       console.error('Error:', error)
  //     })
  // }, [])
  // useEffect(() => {
  // }, [readingMin])


  useEffect(() => {
    const isEnglish = t('MEDIA.LANG') === 'en';
    setTab(isEnglish);
  }, [t]);
  
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 4,
    centerPadding: "20px",

    responsive: [
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };



  return (
    <>
      <div className="min-h-screen pt-52 pb-16 flex flex-col justify-center relative z-10 overflow-hidden">
        <div className="sm:w-3/5 flex flex-col lg:px-120 md:px-10 px-4">
          <H2_LG
            className="animated fadeInLeft"
            style={{ animationDelay: ".4s" }}
          >
            {t('MEDIA.TITLE')}
          </H2_LG>
        </div>
        <div
          className="media lg:px-120 md:px-10 px-4 py-7 mt-16 animated fadeIn"
          style={{ animationDelay: ".6s" }}
        >
          <div className="media-tab flex justify-between items-center">
            <H2_MD className="xl:!text-4xl-m" style={{ opacity: ".5" }}>
              {t('MEDIA.PRESS')}
            </H2_MD>
          </div>
          {tab ? (
            <MediaBox
              title="COVENLABS BRINGS BRANDS TO WEB3"
              description="CovenLabs which develops blockchain-based Web3-oriented
            projects, was founded by Mihriban Ersin Tekmen, also known
            for being the co-founder of Colendi, and Mert Fırat..."
              img={PressRelease1}
              href={"/media/press-release/CovenLabs-Press-Release-1.pdf"}
            />
          ) : (
            <MediaBox
              title="COVENLABS MARKALARI WEB3’E TAŞIYOR"
              description="Blockchain tabanlı, Web3 odaklı projeler geliştiren
              CovenLabs, Colendi Kurucu Ortağı Mihriban Ersin Tekmen ve
              oyunculuğunun yanında girişimci ve yatırımcı kimliği ile de
              ön planda olan Mert Fırat..."
              img={PressRelease1}
              href={"/media/press-release/CovenLabs-Basin-Bulteni-1.pdf"}
            />
          )}
        </div>
        <div
          className="newsroom lg:px-120 md:px-10 px-4  mt-16 animated fadeIn"
          style={{ animationDelay: ".6s" }}
        >
          <div className="media-tab flex justify-between items-center">
            <H2_MD className="xl:!text-4xl-m" style={{ opacity: ".5" }}>
              {t('MEDIA.NEWS')}
            </H2_MD>
          </div>
          <Slider {...settings} className="mx-12 -mt-7">
            <div className="py-7 px-3">
              <MediaBox
                slide={true}
                title="CovenLabs, Web3 EKOSİSTEMİNİ BÜYÜTMEYİ HEDEFLİYOR"
                description='Colendi Kurucu Ortağı Mihriban Ersin Tekmen, "Bu yeni dünyada CovenLabs, fiziksel ve dijital dünyalar arasında bir köprü olacak" dedi. CovenLabs, fiziksel dünyadaki markalara dijital dünyada kişiselleştirilmiş...'
                img={NewsAA}
                href={"/media/newsroom/aa.pdf"}
              />
            </div>
            <div className="py-7 px-3">
              <MediaBox
                slide={true}
                title="FİZİKSEL ve DİJİTAL Dünyalar BİRLEŞİYOR"
                description="Colendi Kurucu Ortağı, seri girişimci Mihriban Ersin Tekmen ile oyunculuğu kadar girişimci ve yatırımcı kimliğiyle de ön planda olan Mert Fırat blockchain tabanlı, Web3 odaklı projeler geliştiren CovenLabs'ı kurdu..."
                img={NewsAksam}
                href={"/media/newsroom/aksam.pdf"}
              />
            </div>
            <div className="py-7 px-3">
              <MediaBox
                slide={true}
                title="CovenLabs Markalarını Web3'e Taşıyor"
                description="Markaların ve şirketlerin Web3 dünyasında yerlerini alabilmeleri için kişiselleştirilmiş çözümler üretmenin yanı sıra projelere işlevsellik, etkileşim ve iş birliği konusunda da destek verme hedefiyle kurulan CovenLabs..."
                img={NewsAnka}
                href={"/media/newsroom/anka.pdf"}
              />
            </div>
            <div className="py-7 px-3">
              <MediaBox
                slide={true}
                title="CovenLabs Fosters Web3 Adoptions"
                description="CovenLabs aims to broaden the Web3 ecosystem by offering tailored solutions for brands and businesses to establish their presence in this realm and by supporting initiatives related to functionality, interaction, and..."
                img={NewsCointrust}
                href={"/media/newsroom/cointrust.pdf"}
              />
            </div>
            <div className="py-7 px-3">
              <MediaBox
                slide={true}
                title="CovenLabs Brings Brands to Web3"
                description="CovenLabs, which was established with the aim of providing personalized solutions for brands and companies to take their place in the Web3 world, as well as supporting projects in functionality, interaction and cooperation..."
                img={NewsGearrice}
                href={"/media/newsroom/gearrice.pdf"}
              />
            </div>
            <div className="py-7 px-3">
              <MediaBox
                slide={true}
                title="CovenLabs Markaları Web3'e Taşımayı HEDEFLİYOR"
                description="Blockchain tabanlı, Web3 odaklı projeler geliştiren CovenLabs, Colendi Kurucu Ortağı Mihriban Ersin Tekmen ve oyunculuğunun yanında girişimci ve yatırımcı kimliği ile de ön planda olan Mert Fırat tarafından kuruldu..."
                img={NewsIha}
                href={"/media/newsroom/iha.pdf"}
              />
            </div>
            <div className="py-7 px-3">
              <MediaBox
                slide={true}
                title="FİZİKSEL ve DİJİTAL Dünyalar BİRLEŞİYOR"
                description="Blockchain tabanlı, Web3 odaklı projeler geliştiren CovenLabs, Colendi Kurucu Ortağı Mihriban Ersin Tekmen ve oyunculuğunun yanında girişimci ve yatırımcı kimliği ile de ön planda olan Mert Fırat tarafından kuruldu..."
                img={NewsPosta}
                href={"/media/newsroom/posta.pdf"}
              />
            </div>
          </Slider>
          {/* {blogs?.length !== 0 && <>
            <div className="media-tab flex justify-between items-center">
              <H2_MD className="xl:!text-4xl-m" style={{ opacity: ".5" }}>
                BLOGS
              </H2_MD>
            </div>
            <Slider {...settings} className="mx-12 -mt-7">
              {blogs?.map((blog) => (
                id = blog.id,
                blog = blog?.attributes,
                <div className="py-7 px-3">
                  <MediaBox
                    slide={true}
                    title={blog?.title}
                    description={blog?.description}
                    minutes={readingMin[id - 1]}
                    img={baseUrl + blog?.cover.data.attributes.url}
                    href={`/blog/${blog.slug}`}
                  />
                </div>
              ))}
            </Slider>
          </>
          } */}
        </div>
        <div className="flex flex-col lg:px-120 md:px-10 px-4 mt-16">
          <div
            className="md:flex block  items-center animated fadeInUp"
            style={{ animationDelay: ".9s" }}
          >
            <H2_MD className="xl:!text-4xl-m md:mr-10 md:mb-0 mb-4">
              {t('MEDIA.KIT')}
            </H2_MD>{" "}
            <ALink
              className="lg:h-[78px] !px-8 lg:!py-6 max-w-md w-full !text-left justify-between no-hover-bg"
              href={`/media/CovenLabs-media-kit.zip`}
            >
              {t('MEDIA.DOWNLOAD')}
              <img
                src={Arrow}
                alt="Download the Media Kit"
                className="w-9 inline ml-auto"
              />
            </ALink>
          </div>
          <div className="animated fadeInUp" style={{ animationDelay: "1.1s" }}>
            <SocialMedia gap="mt-8 justify-between max-w-md w-full" />
          </div>
        </div>

        <img
          className="absolute object-cover w-full h-full -z-20 left-0 top-0"
          src={Background}
          alt=""
        />
      </div>
    </>
  );
};

export default Media;
