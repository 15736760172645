import { Link } from "react-router-dom";
import useChangeWidth from "../../hooks/useChangeWidth";
import { P_MD } from "../../components/Typography";
import SocialMedia from "../../components/SocialMedia";
import EmailForm from "../../components/form/email/EmailForm";
import { subscribe } from "../../constants";
import "./Footer.scss";
import { Fragment, useState } from "react";
import SuccessModal from "../../components/form/successModal/SuccessModal";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const isSize = useChangeWidth(1024);
  const [isOpen, setIsOpen] = useState(false);
  const modalHandle = () => setIsOpen(true);
  const closeModalState = () => {
    setTimeout(() => {
      setIsOpen(false);
    }, 800);
  };

  const { t } = useTranslation();

  return (
    <>
      <footer id="footer" className="lg:px-120 md:px-10 px-4 z-40 relative">
        <div className="footer-container lg:grid 2xl:gap-24 gap-12 xl:pt-14 lg:pt-16 pt-6 xl:pb-20 lg:pb-16 pb-6">
          <div className="separator flex items-center justify-between lg:pb-0 pb-6">
            <Link to="/" reloadDocument>
              <img
                className="duration-500 lg:w-[170px] w-[97px]"
                src={require(`../../assets/images/logo.png`)}
                alt="Covenlabs"
                srcSet={`${require(`../../assets/images/logo.png`)} 1x, ${require(`../../assets/images/logo@2x.png`)} 2x`}
              />
            </Link>
            {!isSize ? (
              <SocialMedia
                size="2xl:w-[32px] xl:w-[24px] w-[16px]"
                gap="2xl:gap-7 lg:gap-3 gap-4"
              />
            ) : null}
          </div>
          <div className="separator lg:py-0 py-6">
            <span className="font-heading 2xl:text-xl xl:text-xl-t text-xl-m">
              {t("FOOTER.CONTACT")}
            </span>
            <P_MD>
              <a
                href="mailto:info@covenlabs.xyz"
                className="xl:mt-5 lg:mt-4 mt-0 block text-[#808080] hover:text-white duration-300 font-medium"
              >
                info@covenlabs.xyz
              </a>
            </P_MD>
          </div>
          <div className="lg:flex justify-between items-center lg:gap-6 lg:pt-0 pt-6">
            <div className="w-full">
              <span className="font-heading 2xl:text-xl xl:text-xl-t text-xl-m">
                {t("FOOTER.SUBSCRIBE")}
              </span>
              <div className="mt-1">
                <EmailForm
                  config={subscribe}
                  btnText={t("FOOTER.BUTTON")}
                  btnClass="footarea"
                  inputClass="but-footarea"
                  modalHandle={modalHandle}
                />
              </div>
            </div>
            <div className="flex flex-col lg:items-end items-center xl:gap-5 gap-3 lg:mt-auto mt-6">
              {isSize ? (
                <SocialMedia
                  size="2xl:w-[32px] xl:w-[24px] w-[16px]"
                  gap="2xl:gap-7 lg:gap-5 gap-4"
                />
              ) : null}
              <span className="font-heading text-base text-silver-sand leading-4">
                © 2022 CovenLabs
              </span>
            </div>
          </div>
        </div>
      </footer>
      {isOpen ? (
        <SuccessModal
          closeModalState={closeModalState}
          title={t("FORM.SUBSCRIBE.TITLE")}
          description={
            <Fragment>
              {t("FORM.SUBSCRIBE.DESCRIPTION1")}
              <br className="md:block hidden" />
              {t("FORM.SUBSCRIBE.DESCRIPTION2")}
            </Fragment>
          }
        />
      ) : null}
    </>
  );
};

export default Footer;
