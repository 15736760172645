import { H3_LG, P_SM } from "../Typography";
import "./Box.scss";

export const Box = ({
  img,
  img2x,
  title,
  content,
  className,
  pictureClass,
}) => {
  return (
    <>
      <div className={`box ${className[0] || ""}`}>
        <div className={`flex items-center box-icon ${pictureClass}`}>
          <picture>
            <img
              src={require(`../../assets/images/${img}`)}
              alt={title}
              srcSet={`${require(`../../assets/images/${img}`)} 1x, ${require(`../../assets/images/${img2x}`)} 2x`}
            />
          </picture>
        </div>
        <H3_LG className="md:tracking-normal tracking-tight">{title}</H3_LG>
        <P_SM>{content}</P_SM>
      </div>
    </>
  );
};

export default Box;
