import Lottie from "lottie-react";
import Loading from "../../assets/lottie/loading.json";
import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import videoTr from "../../assets/video/intro-tr.mp4";
import videoEn from "../../assets/video/intro-en.mp4";
import vidBg from "../../assets/images/covenlabs-about-bg.png";
import videoMobileTr from "../../assets/video/intro-mobile-tr.mp4";
import videoMobileEn from "../../assets/video/intro-mobile-en.mp4";
import vidMobileBg from "../../assets/images/covenlabs-about-bg.png";
import { setIntroStatus } from "./IntroSlice";

const Intro = ({ style, skipToIntro }) => {
  const [scrollLock, setScrollLock] = useState(false);
  const [position, setPosition] = useState(0);
  const [from, setFrom] = useState([0]);
  const [laodVideo, setLaodVideo] = useState(true);
  const [to, setTo] = useState([2.2]);
  const vidRef = useRef();
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(false);
  const [touchStart, setTouchStart] = useState(0);
  const { introComplete } = useSelector((state) => state.intro);
  const userLanguage = navigator.language.split('-')[0];
  const language = localStorage.getItem('language');

  const setPlayingTime = useMemo(
    () => [
      [0, 2.2],
      [2.5, 10.2],
      [10.3, 13.9],
      [14.2, 17.9],
      [18.2, 21.9],
      [22.2, 26.9],
    ],
    []
  );
  const setRewindTime = useMemo(
    () => [
      [45.2, 52],
      [41.2, 44.9],
      [37.2, 40.9],
      [33, 36.9],
    ],
    []
  );

  const handleMouse = useCallback(
    (event) => {
      if (scrollLock && position < setPlayingTime.length - 1) {
        setScrollLock(false);
        if (event.deltaY > 0) {
          setPosition(position + 1);
          setFrom(setPlayingTime[position + 1][0]);
          setTo(setPlayingTime[position + 1][1]);
        } else if (event.deltaY < 0) {
          if (position <= 0) {
            setScrollLock(true);
            return;
          }
          setPosition(position - 1);
          setFrom(setRewindTime[position - 1][0]);
          setTo(setRewindTime[position - 1][1]);
        }
      }
    },
    [scrollLock, position, setPlayingTime, setRewindTime]
  );

  const handlTouchStart = (e) => {
    setTouchStart(e.changedTouches[0].screenY);
  };

  const handlTouchEnd = useCallback(
    (e) => {
      const touchEnd = e.changedTouches[0].screenY;

      if (scrollLock && position < setPlayingTime.length - 1) {
        setScrollLock(false);
        if (touchStart > touchEnd) {
          setPosition(position + 1);
          setFrom(setPlayingTime[position + 1][0]);
          setTo(setPlayingTime[position + 1][1]);
        } else {
          if (position <= 0) {
            setScrollLock(true);
            return;
          }
          setPosition(position - 1);
          setFrom(setRewindTime[position - 1][0]);
          setTo(setRewindTime[position - 1][1]);
        }
      }
    },
    [position, scrollLock, setPlayingTime, setRewindTime, touchStart]
  );

  const handleLoadVideo = () => {
    if (introComplete) {
      vidRef.current.pause();
      return;
    }

    vidRef.current.pause();
    setTimeout(() => {
      setLaodVideo(false);
      vidRef.current && vidRef.current.play();
    }, 1300);
  };

  useEffect(() => {
    if (vidRef.current) {
      vidRef.current.playFragment = function (
        from,
        to,
        frameRate,
        cb,
        vidPlay
      ) {
        const video = this;
        video.currentTime = from === null ? video.currentTime : from;
        vidPlay && video.play();

        const timeCheck = setInterval(function () {
          if (video.currentTime >= to) {
            clearInterval(timeCheck);
            video.pause();
            setScrollLock(true);
            typeof cb === "function" && cb();
          }
        }, 1000 / frameRate);
      };
    }
  }, []);

  useEffect(() => {
    if (scrollLock) {
      window.addEventListener("wheel", handleMouse);
      window.addEventListener("touchstart", handlTouchStart);
      window.addEventListener("touchend", handlTouchEnd);
    } else {
      window.removeEventListener("wheel", handleMouse);
      window.removeEventListener("touchstart", handlTouchStart);
      window.removeEventListener("touchend", handlTouchEnd);
    }
    return () => {
      window.removeEventListener("wheel", handleMouse);
      window.removeEventListener("touchstart", handlTouchStart);
      window.removeEventListener("touchend", handlTouchEnd);
    };
  }, [handlTouchEnd, handleMouse, scrollLock]);

  useEffect(() => {
    if (!introComplete) {
      const playPromise = vidRef.current.play();
      let playing;
      if (playPromise !== undefined) {
        playPromise
          .then(function () {
            playing = true;
          })
          .catch(function (error) {
            playing = false;
          });
      }
      vidRef.current.playFragment(
        from,
        to,
        25,
        () => {
          if (position === setPlayingTime.length - 1) {
            setScrollLock(false);
            dispatch(setIntroStatus(true));
            return;
          }
        },
        playing ? true : false
      );
      document.documentElement.classList.add("overflow-hidden");
    } else {
      // vidRef.current.pause();
      // vidRef.current.currentTime = 14.2;
      document.documentElement.classList.remove("overflow-hidden");
    }
  }, [dispatch, from, introComplete, position, setPlayingTime, to]);

  useEffect(() => {
    setIsMobile(window.innerWidth - window.innerHeight > 0);
  }, []);

  return (
    <>
      {(laodVideo && !introComplete) || skipToIntro ? (
        <Lottie
          className="loader flex items-center justify-center w-screen h-screen bg-black fixed z-50 overflow-hidden"
          animationData={Loading}
          loop={true}
        />
      ) : null}

      {isMobile
        ? !introComplete && (
          <video
            onLoadedData={handleLoadVideo}
            ref={vidRef}
            className="w-full min-w-[375px] h-screen fixed object-cover top-0 left-0 -z-10"
            src={language ? (language === "en" ? videoEn : videoTr) : (userLanguage === "en" ? videoEn : videoTr)}
            playsInline
            muted
          />
        )
        : !introComplete && (
          <video
            autoPlay
            onLoadedData={handleLoadVideo}
            ref={vidRef}
            className="w-full min-w-[375px] h-screen fixed object-cover top-0 left-0 -z-10"
            src={language ? (language === "en" ? videoMobileEn : videoMobileTr) : (userLanguage === "en" ? videoMobileEn : videoMobileTr)}
            playsInline
            muted
          />
        )}

      {isMobile ? (
        <img
          src={vidBg}
          className="w-full min-w-[375px] h-[calc(100vh_+_23%)] absolute object-cover 2xl:object-none -top-12 left-0 -z-20"
          alt=""
          style={{ ...style }}
        />
      ) : (
        <img
          src={vidMobileBg}
          className="w-full min-w-[375px] h-screen absolute object-cover top-0 left-0 -z-20"
          alt=""
          style={{ ...style }}
        />
      )}
    </>
  );
};

export default memo(Intro);
