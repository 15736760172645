import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useLayoutEffect } from "react";
import { setIntroStatus } from "./features/intro/IntroSlice";
import RouteComponent from "./routes";

function App() {
  const { introComplete } = useSelector((state) => state.intro); //intro video izleme durumu
  const location = useLocation();
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    if (location.pathname !== "/" && !introComplete) {
      dispatch(setIntroStatus(true));
    }
  }, [dispatch, introComplete, location]);

  return (
    <>
      <RouteComponent />
    </>
  );
}

export default App;
