import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  introComplete: false,
};

export const introSlice = createSlice({
  name: "intro",
  initialState,
  reducers: {
    setIntroStatus: (state, { payload }) => {
      state.introComplete = payload;
    },
  },
});

export const { setIntroStatus } = introSlice.actions;

export default introSlice.reducer;
