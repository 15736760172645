import "./Button.scss";

export const ALink = ({ href, children, className, style, target }) => {
  return (
    <>
      <a
        className={`dark-button inline-flex lg:rounded-[30px] rounded-[20px] cursor-pointer relative top-0 text-center justify-center lg:text-[27px] text-lg leading-[34px] z-[1] overflow-hidden ${className}`}
        href={href}
        style={{ ...style }}
        target={target}
      >
        <span className="flex w-full font-heading items-center">
          {children}
        </span>
      </a>
    </>
  );
};

export const Button = ({
  type = "submit",
  children,
  className,
  style,
  onClick,
  disabled,
}) => {
  return (
    <>
      <button
        type={type}
        className={`dark-button inline-flex lg:rounded-[30px] rounded-[20px] cursor-pointer relative top-0 text-center justify-center lg:text-[27px] text-lg leading-[34px] z-[1] overflow-hidden ${className}`}
        style={{ ...style }}
        onClick={onClick}
        disabled={disabled}
      >
        <span className="font-heading">{children}</span>
      </button>
    </>
  );
};
