import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { Button } from "../button/Button";
import "./EmailForm.scss";
import { useState } from "react";
import Loader from "../../../assets/images/loader.svg";
import { useTranslation } from "react-i18next";

const EmailForm = ({
  config,
  btnText,
  btnClass = "",
  inputClass = "",
  modalHandle,
}) => {
  const [error, setError] = useState("");

  const EmailFormSchema = Yup.object().shape({
    email: Yup.string()
      .email(config.validation.email)
      .required(config.required.email),
  });

  const { t } = useTranslation();

  const topic = (btnText === "SUBSCRIBE" || btnText === "ABONE OL") ? "NEWSLETTER" : "WAITLIST";

  const onSubmit = (values, { resetForm, setSubmitting }) => {
    setSubmitting(true);
    const response = axios.post(config.api, values, {
      headers: {
        'channel': topic === "WAITLIST" ? "CLUB" : "WEBSITE",
        'language': localStorage.getItem('language') || 'tr'
      }
    });

    response
      .then((responseData) => {
        if (responseData.data.success === true) {
          modalHandle();
          resetForm();
        }
        else {
          setError(config.error);
        }
      })
      .catch((err) => {
        console.log(err);
        setError(config.error);
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const onChange = () => setError("");

  return (
    <>
      <Formik
        initialValues={{
          email: "",
          topic,
        }}
        validationSchema={EmailFormSchema}
        onSubmit={onSubmit}
      >
        {({ errors, touched, isSubmitting, values }) => (
          <Form className="emailform">
            <div
              className={`relative ${(errors.email && touched.email && values.email !== "") ||
                (touched.email && error !== "")
                ? "is-invalid"
                : ""
                }`}
            >
              {(errors.email && touched.email && values.email !== "") ||
                (touched.email && error !== "") ? (
                <div className="is-invalid-message">
                  {errors.email ?? error}
                </div>
              ) : null}
              <Field
                name="email"
                className={`${inputClass} emailform-input w-full lg:py-8 py-5 lg:pl-8 pl-5 lg:pr-[290px] pr-[190px] lg:rounded-[30px] rounded-[20px] text-silver-sand placeholder:text-[#808080] lg:text-base text-base-m`}
                type="text"
                placeholder={t("FOOTER.INPUT")}
                onKeyUp={onChange}
              />
              <Button
                disabled={isSubmitting}
                className={`right-0 no-hover mr-[1px] mt-[1px] ${btnClass}`}
                style={{ position: "absolute", borderWidth: "0 0 0 1px" }}
              >
                {isSubmitting ? <img src={Loader} alt="" /> : btnText}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default EmailForm;
