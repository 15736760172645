import { H2_MD, P_2MD } from "../../../components/Typography";
import Box from "../../../components/box/Box";
import { useScrollAnimate } from "../../../hooks/useScrollAnimate";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";

const LaunchPad = () => {
  const [xValue, setXvalue] = useState();
  const sectionRef = useRef();
  const listContainerRef = useRef();
  const scRatio = useScrollAnimate({
    ref: sectionRef,
    scenes: isMobile ? 1 : 5,
  });

  //yatay carousel içeriğinin resize olma durumunda tekrardan width değerinin alınması x-callback
  const xCb = useCallback(() => {
    setXvalue(
      listContainerRef.current.scrollWidth - sectionRef.current.clientWidth
    );
    listContainerRef.current.scrollLeft = !isMobile
      ? ((scRatio - 1) * xValue) / 4
      : listContainerRef.current.scrollLeft;
  }, [scRatio, xValue]);

  const { t } = useTranslation();

  useEffect(() => {
    xCb();
    window.addEventListener("resize", xCb);
    return () => {
      window.removeEventListener("resize", xCb);
    };
  }, [xCb]);

  const styles = {
    a1: {
      opacity:
        scRatio > 0.5 ? "1" : scRatio < 0.25 ? "0" : (scRatio - 0.25) * 4,
      transform: `translateX(${scRatio > 0.5
        ? "0px"
        : scRatio < 0.25
          ? "-100px"
          : `-${100 - (scRatio - 0.25) * 100 * 4}px`
        })`,
    },
    a2: {
      opacity:
        scRatio > 0.65 ? "1" : scRatio < 0.35 ? "0" : (scRatio - 0.35) * 4,
    },
    a3: {
      opacity: scRatio > 0.75 ? "1" : scRatio < 0.5 ? "0" : (scRatio - 0.5) * 4,
    },
  };

  return (
    <>
      <section
        ref={sectionRef}
        className={`${isMobile ? "h-[100vh]" : "h-[500vh]"} xl:mt-28`}
      >
        <div className="h-screen 2xl:min-h-[898px] lg:min-h-[792px]  md:min-h-[688px] min-h-[616px] sticky top-0">
          <div className="h-full flex flex-col justify-center overflow-hidden lg:pt-[110px] pt-20">
            <div className="lg:max-w-[1000px] xl:max-w-[1240px] max-w-[575px] lg:mx-120 md:mx-10 mx-4 md:!m-auto xl:!mb-0 xl:!mt-0">
              <H2_MD>
                <span className="block md:text-center" style={styles.a1}>
                  {t("HOME.LAUNCHPAD_TITLE")}
                </span>
              </H2_MD>
              <div className="flex gap-2 md:justify-center">
                <P_2MD className="mt-3 md:text-center" style={styles.a2}>
                  {t("HOME.LAUNCHPAD_DESCRIPTION")}
                </P_2MD>
                <P_2MD className="mt-3 md:text-center" style={styles.a2}>
                  {t("HOME.LAUNCHPAD_DESCRIPTION2")}
                </P_2MD>
              </div>
              <P_2MD className="mt-3 md:text-center" style={styles.a3}>
                {t("HOME.LAUNCHPAD_DESCRIPTION3")}
              </P_2MD>
            </div>
            <div
              ref={listContainerRef}
              className={`${isMobile ? "overflow-auto" : "overflow-hidden"
                } xl:pb-8 2xl:mt-10 mt-5 whitespace-nowrap h-scroll lg:px-120 md:px-10 px-4`}
            >
              <Box
                className={[
                  `box-s1 animated ${scRatio > (isMobile ? 0.65 : 0.7)
                    ? "fadeInRight"
                    : "fadeOutRight"
                  }`,
                  "box-d1",
                ]}
                title={t("HOME.LAUNCH_TITLE")}
                content={t("HOME.LAUNCH_DESCRIPTION")}
                img="ic-launch-strategy.png"
                img2x="ic-launch-strategy@2x.png"
                pictureClass="box-icon-a1"
              />
              <Box
                className={[
                  `box-s2 animated ${scRatio > (isMobile ? 0.65 : 0.75)
                    ? "fadeInRight"
                    : "fadeOutRight"
                  }`,
                  "box-d2",
                ]}
                title={t("HOME.LOYALTY_TITLE")}
                content={t("HOME.LOYALTY_DESCRIPTION")}
                img="ic-loyalty.png"
                img2x="ic-loyalty@2x.png"
                pictureClass="box-icon-a2"
              />
              <Box
                className={[
                  `box-s2 animated ${scRatio > (isMobile ? 0.65 : 0.75)
                    ? "fadeInRight"
                    : "fadeOutRight"
                  }`,
                  "box-d2",
                ]}
                title={t("HOME.SHARED_TITLE")}
                content={t("HOME.SHARED_DESCRIPTION")}
                img="ic-shared-economy.png"
                img2x="ic-shared-economy@2x.png"
                pictureClass="box-icon-a2"
              />
              <Box
                className={[
                  `box-s2 animated ${scRatio > (isMobile ? 0.65 : 0.75)
                    ? "fadeInRight"
                    : "fadeOutRight"
                  }`,
                  "box-d2",
                ]}
                title={t("HOME.POINTS_TITLE")}
                content={t("HOME.POINTS_DESCRIPTION")}
                img="ic-point-tokenomics.png"
                img2x="ic-point-tokenomics@2x.png"
                pictureClass="box-icon-a2"
              />
              <Box
                className={[
                  `box-s1 animated ${scRatio > (isMobile ? 0.65 : 0.8)
                    ? "fadeInRight"
                    : "fadeOutRight"
                  }`,
                  "box-d3",
                ]}
                title={t("HOME.CEFI_TITLE")}
                content={t("HOME.CEFI_DESCRIPTION")}
                img="ic-cefi-integration.png"
                img2x="ic-cefi-integration@2x.png"
                pictureClass="box-icon-a1"
              />
              <Box
                className={[
                  `box-s2 animated ${scRatio > (isMobile ? 0.65 : 0.85)
                    ? "fadeInRight"
                    : "fadeOutRight"
                  }`,
                  "box-d1",
                ]}
                title={t("HOME.BRAND_TITLE")}
                content={t("HOME.BRAND_DESCRIPTION")}
                img="ic-brand-positioning.png"
                img2x="ic-brand-positioning@2x.png"
                pictureClass="box-icon-a2"
              />
              <Box
                className={[
                  `box-s1 animated ${scRatio > (isMobile ? 0.65 : 0.9)
                    ? "fadeInRight"
                    : "fadeOutRight"
                  }`,
                  "box-d2",
                ]}
                title={t("HOME.CONTENT_TITLE")}
                content={t("HOME.CONTENT_DESCRIPTION")}
                img="ic-content-community-building.png"
                img2x="ic-content-community-building@2x.png"
                pictureClass="box-icon-a1"
              />
              <Box
                className={[
                  `box-s1 animated ${scRatio > (isMobile ? 0.65 : 0.9)
                    ? "fadeInRight"
                    : "fadeOutRight"
                  }`,
                  "box-d2",
                ]}
                title={t("HOME.CUSTOM_TITLE")}
                content={t("HOME.CUSTOM_DESCRIPTION")}
                img="ic-development.png"
                img2x="ic-development@2x.png"
                pictureClass="box-icon-a1"
              />
            </div>
          </div>
        </div>
      </section >
    </>
  );
};

export default memo(LaunchPad);
