import { ALink } from "../components/form/button/Button";
import { H2_LG, P_2MD } from "../components/Typography";
import Arrow from "../assets/images/ic-arrow.svg";
import Background from "../assets/images/career-bg.png";
import { useTranslation } from "react-i18next";

const Career = () => {

  //   const data = [{
  //     title: "Senior Backend Developer",
  //     location: "Istanbul, Turkey",
  //     image: "https://www.flaticon.com/svg/vstatic/svg/2919/2919600.svg?token=exp=163394",
  //     description: "Vorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Vorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.",
  //     responsibilities: "Delivering quality software, Participate in know-how building, Participate in developing life-changing financial products.",
  //     qualifications: "Bachelor's degree and relevant experience, 5+ years' experience programming on Java, Experience building Restful Backend Services with Spring Boot Framework, Experience with Relational and Non Relational database solutions, Experience with ORM tools such as Hibernate, JPA or other frameworks, Knowledge of data structures, design patterns and SOLID principles, Strong unit test and debugging skills, Familiarity with caching solutions (Redis, Hazelcast etc.), Familiarity with messaging solutions (Rabbit, Kafka etc.), Familiarity with micro services architecture, Demonstrated expertise in developing top notch software, Strong organizational, critical thinking and communications skills, Attention to detail and good judgment, Experience working in Financial Services large-scale platforms and programs, is a plus."
  // },
  // {
  //     title: "Senior Backend Developer",
  //     location: "Istanbul, Turkey",
  //     image: "https://www.flaticon.com/svg/vstatic/svg/2919/2919600.svg?token=exp=163394",
  //     description: "Vorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Vorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vulputate libero et velit interdum, ac aliquet odio mattis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.",
  //     responsibilities: "Delivering quality software, Participate in know-how building, Participate in developing life-changing financial products.",
  //     qualifications: "Bachelor's degree and relevant experience, 5+ years' experience programming on Java, Experience building Restful Backend Services with Spring Boot Framework, Experience with Relational and Non Relational database solutions, Experience with ORM tools such as Hibernate, JPA or other frameworks, Knowledge of data structures, design patterns and SOLID principles, Strong unit test and debugging skills, Familiarity with caching solutions (Redis, Hazelcast etc.), Familiarity with messaging solutions (Rabbit, Kafka etc.), Familiarity with micro services architecture, Demonstrated expertise in developing top notch software, Strong organizational, critical thinking and communications skills, Attention to detail and good judgment, Experience working in Financial Services large-scale platforms and programs, is a plus."
  // }]

  const { t } = useTranslation();
  // const [isOpen, setIsOpen] = useState(false);
  // const [isAbsolute, setIsAbsolute] = useState(false);

  // const closeModalState = () => {
  //   setTimeout(() => {
  //     setIsOpen(false);
  //   }, 800);
  // };

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const socialFloat = document.getElementById("social-float");
  //     const footer = document.getElementById("footer");

  //     if (socialFloat && footer) {
  //       const socialFloatTop =
  //         socialFloat.getBoundingClientRect().top + window.scrollY;
  //       const footerTop = footer.getBoundingClientRect().top + window.scrollY;

  //       setIsAbsolute((prevIsAbsolute) => {
  //         if (
  //           socialFloatTop + socialFloat.offsetHeight >= footerTop &&
  //           !prevIsAbsolute
  //         ) {
  //           socialFloat.style.position = "absolute";
  //           return true;
  //         }

  //         if (
  //           window.scrollY + window.innerHeight < footerTop &&
  //           prevIsAbsolute
  //         ) {
  //           socialFloat.style.position = "fixed";
  //           return false;
  //         }

  //         socialFloat.textContent = window.scrollY + window.innerHeight;

  //         // No state change
  //         return prevIsAbsolute;
  //       });
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  return (
    <>
      {/* {isOpen &&
        <Apply
          closeModalState={closeModalState}
        />
      } */}
      <div className="lg:px-120 md:px-10 px-4 min-h-screen lg:pt-[182px] pt-20 sm:pb-8 pb-64 flex flex-col justify-center md:justify-start items-center relative z-10 overflow-hidden">
        <div className="sm:w-full">
          <H2_LG
            className="text-center animated fadeInUp"
            style={{ animationDelay: ".4s" }}
          >
            {t("CAREER.TITLE")}
          </H2_LG>
          <P_2MD
            className="text-center mt-3 animated fadeInUp"
            style={{ animationDelay: ".7s" }}
          >
            {t("CAREER.DESCRIPTION")}
          </P_2MD>
          <div
            dangerouslySetInnerHTML={{ __html: t("CAREER.DESCRIPTION2") }}
            className="text-center mt-5 lg:text-2md text-2md-m font-light text-silver-sand animated fadeInUp"
            style={{ animationDelay: ".7s" }}
          >

          </div>
          <ALink
            className="animated fadeInUp mt-10 lg:h-[78px] !px-8 lg:!py-6 max-w-md w-full !text-left justify-between no-hover-bg"
            href="mailto:career@covenlabs.xyz"
            style={{ animationDelay: ".9s" }}
          >
            {t("CAREER.CONTACT")}
            <img src={Arrow} alt="Contact Us" className="w-9 inline ml-auto" />
          </ALink>
          {/* <div className="flex h-full">
            <div className="w-full relative">
              <H2_SM
                className="text-start mt-20 mr-auto animated fadeInUp"
                style={{ animationDelay: "1.1s" }}
              >{t('CAREER.JOIN')}</H2_SM>
              <JobAccordion data={data} />
            </div>
          </div> */}
          <img
            className="absolute object-cover w-full h-screen -z-20 left-0 top-0"
            src={Background}
            alt=""
          />
        </div>
        {/* <img
          className="absolute object-cover animated rotateInfinite sm:w-2/5 w-3/4 sm:right-5 right-[12%] sm:bottom-auto bottom-5 -z-10 max-w-[625px]"
          src={Round}
          alt=""
        /> */}
        {/* <img
          id="social-float"
          className={`object-cover sm:right-5 right-[12%] bottom-0 -z-10 ${isAbsolute ? "absolute bottom-0" : "fixed"}`}
          src={Round}
          alt=""
        /> */}
      </div>
    </>
  );
};

export default Career;
