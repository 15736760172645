import { memo, useRef, useState } from "react";
import { H2_LG, P_2MD } from "../../../components/Typography";
import { useScrollAnimate } from "../../../hooks/useScrollAnimate";
import Background from "../../../assets/images/covenlabs-bg.jpg";
import useChangeWidth from "../../../hooks/useChangeWidth";
import WaitlistForm from "../../../components/waitlist/Waitlist";
import { Button } from "../../../components/form/button/Button";
import { useTranslation } from "react-i18next";

const CovenlabsClub = () => {
  const sectionRef = useRef();
  const isSize = useChangeWidth(1024);
  const [isOpen, setIsOpen] = useState(false);
  const closeModalState = () => {
    setTimeout(() => {
      setIsOpen(false);
    }, 800);
  };
  const scRatio = useScrollAnimate({
    ref: sectionRef,
    scenes: 2,
  });

  const { t } = useTranslation();

  const styles = {
    bg: {
      opacity: scRatio >= 1.5 ? "1" : scRatio < 0.5 ? "0" : scRatio - 0.5,
      transform: `scale(${scRatio >= 1.5 ? "1" : scRatio < 0.5 ? "2" : 2.5 - scRatio
        }) rotate(${isSize
          ? scRatio >= 1.5
            ? "360"
            : scRatio < 0.5
              ? "0"
              : (scRatio - 0.5) * 360
          : 0
        }deg)`,
    },
    a1: {
      color: "white",
      opacity: scRatio >= 1.5 ? "1" : scRatio < 1 ? "0" : (scRatio - 1) * 2,
      transform: `translateX(${scRatio >= 1.5
        ? "0px"
        : scRatio < 1
          ? "-50%"
          : `-${50 - (scRatio - 1) * 100}%`
        })`,
    },
    a2: {
      opacity: scRatio >= 1.5 ? "1" : scRatio < 1 ? "0" : (scRatio - 1) * 2,
      transform: `translateX(${scRatio >= 1.5
        ? "0px"
        : scRatio < 1
          ? "-50%"
          : `${50 - (scRatio - 1) * 100}%`
        })`,
    },
    a3: {
      opacity:
        scRatio >= 1.65 ? "1" : scRatio < 1.5 ? "0" : (scRatio - 1.5) * 6.66,
      transform: `translateY(${scRatio >= 1.65
        ? "0px"
        : scRatio < 1.5
          ? "50%"
          : `${50 - (scRatio - 1.5) * 333}%`
        })`,
    },
    a4: {
      opacity:
        scRatio >= 1.8 ? "1" : scRatio < 1.65 ? "0" : (scRatio - 1.65) * 4,
      transform: `translateY(${scRatio >= 1.8
        ? "0px"
        : scRatio < 1.65
          ? "50%"
          : `${50 - (scRatio - 1.65) * 333}%`
        })`,
    },
    a5: {
      opacity:
        scRatio >= 1.95 ? "1" : scRatio < 1.8 ? "0" : (scRatio - 1.8) * 4,
      transform: `translateY(${scRatio >= 1.95
        ? "0px"
        : scRatio < 1.8
          ? "50%"
          : `${50 - (scRatio - 1.8) * 333}%`
        })`,
    },
  };

  return (
    <section ref={sectionRef} className="h-[250vh] z-50  bg-black relative">
      <div className="h-screen xl:min-h-[650px] lg:min-h-[666px] min-h-[518px] sticky top-0">
        <div className="h-full flex w-full lg:px-24 md:px-10 px-4 flex-col justify-center items-center overflow-hidden lg:pt-[110px] pt-20 pb-5">
          <div className="text-center xl:max-w-[1000px]">
            <H2_LG>
              <span className="flex justify-center" style={styles.a1}>
                {t("CLUB.TITLE")}
              </span>
              <span className="flex justify-center" style={styles.a2}>
                {t("CLUB.TITLE2")}
              </span>
            </H2_LG>
            <P_2MD className="mt-3 text-silver-sand !font-medium" style={styles.a3}>
              {t("CLUB.DESCRIPTION")}
            </P_2MD>
            <P_2MD
              className="mt-4 text-silver-sand !font-small"
              style={styles.a3}
            >
              {t("CLUB.DESCRIPTION2")}
            </P_2MD>
            <P_2MD
              className="mt-4 text-silver-sand !font-small"
              style={styles.a4}
            >
              {t("CLUB.DESCRIPTION3")}
            </P_2MD>
            <div
              className="mx-auto mt-4 lg:max-w-xl max-w-lg w-full"
              style={styles.a4}
            ></div>
          </div>
          <Button
            className="mt-1"
            style={styles.a4}
            onClick={() => setIsOpen(true)}
          >
            {t("CLUB.JOIN")}
          </Button>
          <P_2MD
              className="mt-8 text-silver-sand !font-small text-center"
              style={styles.a5}
            >
              {t("CLUB.DESCRIPTION4")}
            </P_2MD>
        </div>
        {
          isOpen ? (
            <WaitlistForm
              closeModalState={closeModalState}
            />
          ) : null
        }
        <div className="h-screen absolute top-0 left-0 right-0 w-full overflow-hidden -z-10 min-w-[375px]">
          <img
            src={Background}
            alt=""
            style={styles.bg}
            className="absolute top-0 left-0 object-cover h-full w-full"
          />
        </div>
      </div>
    </section>
  );
};

export default memo(CovenlabsClub);
